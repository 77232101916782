import { LicenseService } from "../services";

export const license = {
  state: () => ({
    pending: [],
    licenses: [],
    selectedLicenses: [],
    licenseType: [],
  }),
  mutations: {
    setPending: (state, value) => (state.pending = value),
    setLicenses: (state, value) => (state.licenses = value),
    setSelectedLicenses: (state, value) => (state.selectedLicenses = value),
    setLicenseType: (state, value) => (state.licenseType = value),
  },
  actions: {
    LICENSE_FIND_ALL: async ({ commit }, payload) => {
      const response = await LicenseService.findAll(payload);
      if (response.ok) {
        commit("setLicenses", response.licenses);
        commit("setPending", response.pending);
      } else {
        alert(response.message);
      }
    },
    LICENSE_TYPE: async ({ commit }, payload) => {
      const response = await LicenseService.type(payload);
      if (response.ok) {
        commit("setLicenseType", response.licenseType);
      } else {
        alert(response.message);
      }
    },
    LICENSE_CREATE: async (_, payload) => await LicenseService.create(payload),
    LICENSE_CHANGE_STATUS: async (_, payload) =>
      await LicenseService.status(payload),
  },
  getters: {},
};
