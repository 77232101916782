import { ReportService } from "../services";

export const report = {
  state: () => ({
    reports: [],
    selectedReports: [],
  }),
  mutations: {
    setReports: (state, value) => (state.reports = value),
    setSelectedReports: (state, value) => (state.selectedReports = value),
  },
  actions: {
    REPORT_FIND_ALL: async ({ commit }, payload) => {
      const response = await ReportService.findAll(payload);
      if (response.ok) {
        commit("setReports", response.reports);
      } else {
        alert(response.message);
      }
    },
    REPORT_CREATE: async (_, payload) => await ReportService.create(payload),
    REPORT_STATUS: async (_, payload) => await ReportService.status(payload),
    REPORT_REMOVE: async (_, payload) => await ReportService.remove(payload),
  },
  getters: {},
};
