import { ShipmentService } from "../services";

export const shipment = {
  state: () => ({
    shipments: [],
    types: [],
    selectedShipments: [],
  }),
  mutations: {
    setShipments: (state, value) => (state.shipments = value),
    setTypes: (state, value) => (state.types = value),
    setSelectedShipments: (state, value) => (state.selectedShipments = value),
  },
  actions: {
    SHIPMENT_FIND_ALL: async ({ commit }, payload) => {
      const response = await ShipmentService.findAll(payload);
      if (response.ok) {
        commit("setShipments", response.shipments);
      } else {
        alert(response.message);
      }
    },
    SHIPMENT_TYPE: async ({ commit }) => {
      const response = await ShipmentService.type();
      if (response.ok) {
        commit("setTypes", response.types);
      } else {
        alert(response.message);
      }
    },
    SHIPMENT_CREATE: async (_, payload) =>
      await ShipmentService.create(payload),
    SHIPMENT_STATUS: async (_, payload) =>
      await ShipmentService.status(payload),
    SHIPMENT_REMOVE: async (_, payload) =>
      await ShipmentService.remove(payload),
  },
  getters: {},
};
