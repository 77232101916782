import { tuApptoAPI } from "./config";

export const findAll = async (request) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.post("/votings/findall", request, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const create = async (request) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.post("/votings/create", request, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const remove = async (id) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.delete(`/votings/delete/${id}`, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const findAllDetail = async (request) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.post(
      "/votings/details/findall",
      request,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const createDetail = async (request) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.post("/votings/details/create", request, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const updateDetail = async (request) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.put("/votings/details/update", request, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const removeDetail = async (id) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.delete(
      `/votings/resolutions/delete/${id}`,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const changeStatus = async (request) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.put("/votings/status/update", request, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
