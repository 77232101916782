import { ReserveService } from "../services";

export const reserve = {
  state: () => ({
    reserves: [],
    reservesHist: [],
    selectedReserves: [],
    availableDays: [],
  }),
  mutations: {
    setReserves: (state, value) => (state.reserves = value),
    setReservesHist: (state, value) => (state.reservesHist = value),
    setSelectedReserves: (state, value) => (state.selectedReserves = value),
    setAvailableDays: (state, value) => (state.availableDays = value),
  },
  actions: {
    RESERVE_FIND_ALL: async ({ commit }, payload) => {
      const response = await ReserveService.findAll(payload);
      if (response.ok) {
        commit("setReserves", response.reserves);
      } else {
        alert(response.message);
      }
    },
    RESERVE_HISTORIC: async ({ commit }, payload) => {
      const response = await ReserveService.historic(payload);
      if (response.ok) {
        commit("setReservesHist", response.reserves);
      } else {
        alert(response.message);
      }
    },
    RESERVE_CREATE: async (_, payload) => await ReserveService.create(payload),
    RESERVE_DELETE: async (_, payload) => await ReserveService.remove(payload),
    RESERVE_CHANGE_STATUS: async (_, payload) =>
      await ReserveService.status(payload),
    RESERVE_AVAILABLE_TURNS: async (_, payload) =>
      await ReserveService.turns(payload),
    RESERVE_DISABLED_DAYS: async ({ commit }, payload) => {
      const response = await ReserveService.disabled(payload);
      if (response.ok) {
        commit("setAvailableDays", response.availableDays);
      } else {
        alert(response.message);
      }
    },
  },
  getters: {},
};
