import { ContractService } from "../services";

export const contract = {
  state: () => ({
    contracts: [],
    selectedContracts: [],
  }),
  mutations: {
    setContracts: (state, value) => (state.contracts = value),
    setSelectedContracts: (state, value) => (state.selectedContracts = value),
  },
  actions: {
    CONTRACT_FIND_ALL: async ({ commit }, payload) => {
      const response = await ContractService.findAll(payload);
      if (response.ok) {
        commit("setContracts", response.contracts);
      } else {
        alert(response.message);
      }
    },
    CONTRACT_CREATE: async (_, payload) =>
      await ContractService.create(payload),
    CONTRACT_CHANGE_STATUS: async (_, payload) =>
      await ContractService.status(payload),
  },
  getters: {},
};
