import { NotificationService } from "../services";

export const notification = {
  state: () => ({}),
  mutations: {},
  actions: {
    NOTIFICATION_DELETE: async ({ dispatch }, payload) => {
      await NotificationService.remove(payload);
      dispatch("AUTH_NOTIFICATION_REMOVE", payload.notificationTypeId);
    },
  },
  getters: {},
};
