import { createStore } from "vuex";
import { auth } from "./auth.store";
import { general } from "./general.store";
import { company } from "./company.store";
import { building } from "./building.store";
import { resident } from "./resident.store";
import { role } from "./role.store";
import { employee } from "./employee.store";
import { apartment } from "./apartment.store";
import { pantry } from "./pantry.store";
import { parking } from "./parking.store";
import { commonArea } from "./commonarea.store";
import { user } from "./user.store";
import { reserve } from "./reserve.store";
import { maintenance } from "./maintenance.store";
import { commonExpense } from "./commonexpense.store";
import { tower } from "./tower.store";
import { assembly } from "./assembly.store";
import { voting } from "./voting.store";
import { notice } from "./notice.store";
import { document } from "./document.store";
import { message } from "./message.store";
import { notification } from "./notification.store";
import { personnel } from "./personnel.store";
import { contract } from "./contract.store";
import { unlink } from "./unlink.store";
import { license } from "./license.store";
import { visit } from "./visit.store";
import { report } from "./report.store";
import { shipment } from "./shipment.store";

export default createStore({
  state: {
    loading: false,
    openMode: null,
    toast: null,
  },
  mutations: {
    setLoading: (state, value) => (state.loading = value),
    setOpenMode: (state, value) => (state.openMode = value),
    setToast: (state, value) => (state.toast = value),
  },
  actions: {},
  modules: {
    auth,
    general,
    company,
    building,
    resident,
    role,
    employee,
    apartment,
    pantry,
    parking,
    commonArea,
    user,
    reserve,
    maintenance,
    commonExpense,
    tower,
    assembly,
    voting,
    notice,
    document,
    message,
    notification,
    personnel,
    contract,
    unlink,
    license,
    visit,
    report,
    shipment,
  },
});
