import { AuthService } from "../services";
import * as constants from "../constants";
import * as masterRoutes from "../router/master.routes";
import * as adminRoutes from "../router/admin.routes";

export const auth = {
  state: () => ({
    isAuth: false,
    menu: [],
    role: { name: "", description: "" },
    modules: [],
    functionalities: [],
    user: null,
    notifications: [],
    buildingBadge: 0,
    paymentBadge: 0,
    assemblyBadge: 0,
    messageBadge: 0,
    licenseBadge: 0,
  }),
  mutations: {
    setIsAuth: (state, value) => (state.isAuth = value),
    setMenu: (state, value) => (state.menu = value),
    setRole: (state, value) => (state.role = value),
    setModules: (state, value) => (state.modules = value),
    setFunctionalities: (state, value) => (state.functionalities = value),
    setNotifications: (state, value) => (state.notifications = value),
    setUser: (state, value) => (state.user = value),
    setBuildingBadge: (state, value) => (state.buildingBadge = value),
    setPaymentBadge: (state, value) => (state.paymentBadge = value),
    setAssemblyBadge: (state, value) => (state.assemblyBadge = value),
    setMessageBadge: (state, value) => (state.messageBadge = value),
    setLicenseBadge: (state, value) => (state.licenseBadge = value),
  },
  actions: {
    AUTH_SIGNIN: async (_, payload) => await AuthService.signin(payload),
    AUTH_CONTACT: async (_, payload) => await AuthService.contact(payload),
    AUTH_ACCESS: async (_, payload) => await AuthService.access(payload),
    AUTH_FORGOT: async (_, payload) => await AuthService.forgot(payload),
    AUTH_VALIDATE: async (_, payload) => await AuthService.validate(payload),
    AUTH_CHANGE: async (_, payload) => await AuthService.change(payload),
    AUTH_UPDATE_USER: async (_, payload) =>
      await AuthService.updateUser(payload),
    AUTH_USER: async ({ commit, dispatch }, { router, device }) => {
      const response = await AuthService.data({ device });
      if (response.ok) {
        const user = response.user;
        const role = user.role;
        commit("setIsAuth", true),
          commit("setRole", role),
          commit("setModules", user.modules),
          commit("setFunctionalities", user.functionalities),
          commit("setNotifications", user.notifications),
          commit("setUser", {
            id: user.id,
            name: user.name,
            email: user.email,
            identity: user.identity,
            phone: user.phone,
            buildings: user.buildings,
            company: user.company,
            logo: user.logo,
            indCreated: user.indCreated,
          });

        if (user.buildings.length === 1)
          commit("setGeneralSelectedBuilding", user.buildings[0].id);

        await dispatch("AUTH_ROUTES", router);
        return true;
      } else {
        alert(response.message);
        return false;
      }
    },
    AUTH_ROUTES: async ({ state, dispatch }, router) => {
      switch (state.role.name) {
        case constants.roles.master:
          await dispatch("AUTH_MASTER", router);
          break;
        default:
          await dispatch("AUTH_ADMIN", router);
          break;
      }
    },
    AUTH_MASTER: ({ commit }, router) => {
      masterRoutes.routes.forEach((route) => router.addRoute("/", route));
      masterRoutes.menu.forEach((route) => {
        router.addRoute("/", route);
        commit("setMenu", constants.menu.masterRoutes);
      });
      router.push("/home");
    },
    AUTH_ADMIN: ({ state, commit, dispatch }, router) => {
      adminRoutes.routes.forEach((route) => {
        const functionality = state.functionalities.find(
          (item) => item.name === route.name
        );
        if (functionality) router.addRoute("/", route);
      });
      const badges = {
        building: 0,
        payment: 0,
        assembly: 0,
        message: 0,
        license: 0,
      };
      adminRoutes.menu.forEach((route) => {
        const item = constants.menu.adminRoutes.find(
          (item) => item.label === route.name
        );
        if (item) {
          let finded = false;
          constants.subMenu[item.key].forEach((el) => {
            if (state.modules.includes(el.label)) {
              finded = true;
            }
          });

          if (finded) {
            router.addRoute("/", route);
            state.notifications.forEach((el) => {
              if (item.key === "building" && el.typeId === 1) {
                badges.building += 1;
              } else if (item.key === "message" && el.typeId === 3) {
                badges.message += 1;
              } else if (item.key === "assembly" && el.typeId === 6) {
                badges.assembly += 1;
              } else if (item.key === "commonExpenses" && el.typeId === 2) {
                badges.payment += 1;
              } else if (item.key === "licenses" && el.typeId === 8) {
                badges.license += 1;
              }
            });
            commit("setBuildingBadge", badges.building);
            commit("setPaymentBadge", badges.payment);
            commit("setAssemblyBadge", badges.assembly);
            commit("setMessageBadge", badges.message);
            commit("setLicenseBadge", badges.license);
            const menu = [...state.menu, item];
            commit("setMenu", menu);
          }
        }
      });
      router.push("/home");
    },
    AUTH_NOTIFICATION: ({ state, commit }, data) => {
      const badges = {
        building: state.buildingBadge,
        payment: state.paymentBadge,
        assembly: state.assemblyBadge,
        message: state.messageBadge,
        license: state.licenseBadge,
      };
      switch (data.typeId) {
        case 1:
          badges.building += 1;
          break;
        case 2:
          badges.payment += 1;
          break;
        case 6:
          badges.assembly += 1;
          break;
        case 3:
          badges.message += 1;
          break;
        case 8:
          badges.license += 1;
          break;
        default:
          break;
      }
      commit("setBuildingBadge", badges.building);
      commit("setPaymentBadge", badges.payment);
      commit("setAssemblyBadge", badges.assembly);
      commit("setMessageBadge", badges.message);
      commit("setLicenseBadge", badges.license);
    },
    AUTH_NOTIFICATION_REMOVE: ({ state, commit }, typeId) => {
      const notifications = state.notifications.filter(
        (item) => item.typeId !== typeId
      );
      const badges = {
        building: state.buildingBadge,
        payment: state.paymentBadge,
        assembly: state.assemblyBadge,
        message: state.messageBadge,
        license: state.licenseBadge,
      };
      const sub = state.notifications.length - notifications.length;
      switch (typeId) {
        case 1:
          badges.building -= sub;
          break;
        case 2:
          badges.payment -= sub;
          break;
        case 6:
          badges.assembly -= sub;
          break;
        case 3:
          badges.message -= sub;
          break;
        case 8:
          badges.license -= sub;
          break;
        default:
          break;
      }
      commit("setBuildingBadge", badges.building);
      commit("setPaymentBadge", badges.payment);
      commit("setAssemblyBadge", badges.assembly);
      commit("setMessageBadge", badges.message);
      commit("setLicenseBadge", badges.license);
      commit("setNotifications", notifications);
    },
  },
  getters: {},
};
