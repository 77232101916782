<template>
  <Button
    icon="pi pi-arrow-left"
    class="p-button-rounded p-button-outlined back"
    @click="onClick"
  />
</template>

<script>
import store from "../../store";
import { useRouter } from "vue-router";

export default {
  props: {
    callback: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const router = useRouter();
    const onClick = async () => {
      props.callback();
      await store.commit("removeBreadcrumb");
      const { length } = store.state.general.breadcrumbs;
      router.push({ path: store.state.general.breadcrumbs[length - 1].to });
    };
    return { store, onClick };
  },
};
</script>

<style scoped>
.back {
  color: var(--primary-dark) !important;
  margin: 0 1rem 0 2rem;
  width: 2rem !important;
  height: 2rem !important;
}
.back:hover {
  color: var(--primary) !important;
  background-color: #ffffff !important;
  box-shadow: none !important;
}
.back:focus,
.back:active {
  color: var(--primary-dark) !important;
  background-color: #ffffff !important;
  box-shadow: none !important;
}
</style>
