import { tuApptoAPI } from "./config";

export const inbox = async (request) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.post("/messages/inbox/findall", request, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const send = async (request) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.post("/messages/send/findall", request, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const create = async (request) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.post("/messages/admin/create", request, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const detail = async (request) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.post("/messages/detail", request, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const categories = async () => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.get("/messages/categories/findall", {
      headers,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const recipients = async (request) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.post(
      "/messages/recipients/findall",
      request,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const sendMessage = async (request) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.post("/messages/status/change", request, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const changeStatus = async (request) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.post(
      "/messages/recipients/status/change",
      request,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
