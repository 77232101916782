import { AssemblyService } from "../services";

export const assembly = {
  state: () => ({
    assemblies: [],
    selectedAssemblies: [],
    resolutions: [],
    selectedResolutions: [],
  }),
  mutations: {
    setAssemblies: (state, value) => (state.assemblies = value),
    setSelectedAssemblies: (state, value) => (state.selectedAssemblies = value),
    setResolutions: (state, value) => (state.resolutions = value),
    setSelectedResolutions: (state, value) =>
      (state.selectedResolutions = value),
  },
  actions: {
    ASSEMBLY_FIND_ALL: async ({ commit }, payload) => {
      const response = await AssemblyService.findAll(payload);
      if (response.ok) {
        commit("setAssemblies", response.assemblies);
      } else {
        alert(response.message);
      }
    },
    ASSEMBLY_CREATE: async (_, payload) =>
      await AssemblyService.create(payload),
    ASSEMBLY_DELETE: async (_, payload) =>
      await AssemblyService.remove(payload),
    ASSEMBLY_RESOLUTION_FIND_ALL: async ({ commit }, payload) => {
      const response = await AssemblyService.findAllResolution(payload);
      if (response.ok) {
        commit("setResolutions", response.resolutions);
      } else {
        alert(response.message);
      }
    },
    ASSEMBLY_RESOLUTION_CREATE: async (_, payload) =>
      await AssemblyService.createResolution(payload),
    ASSEMBLY_RESOLUTION_UPDATE: async (_, payload) =>
      await AssemblyService.updateResolution(payload),
    ASSEMBLY_RESOLUTION_DELETE: async (_, payload) =>
      await AssemblyService.removeAssembly(payload),
  },
  getters: {},
};
