import { initializeApp } from "firebase/app";
import { getToken, onMessage, getMessaging } from "firebase/messaging";
import store from "../store";

export const useNotification = () => {
  const app = initializeApp({
    apiKey: "AIzaSyA8I3iRg7o_hToltnOb4Zx6erI05niLips",
    authDomain: "tuappto-dev-94986.firebaseapp.com",
    projectId: "tuappto-dev-94986",
    storageBucket: "tuappto-dev-94986.appspot.com",
    messagingSenderId: "597007696438",
    appId: "1:597007696438:web:49bbd2e555d5b6b8a44e97",
  });
  const messaging = getMessaging(app);
  const getDevice = getToken(messaging, {
    vapidKey:
      "BLJ_a5UEH0LEf3e02MdGpYorgVgSjYkMFeZUvPU9YhQhvS12vBk9Xbu1pSbyZwhHth7YJK7aff70iiWLgGITIw0",
  });

  onMessage(messaging, (payload) => {
    const notifications = store.state.auth.notifications;
    const data = {
      date: payload.data.date,
      id: +payload.data.id,
      title: payload.data.title,
      text: payload.data.text,
      typeId: +payload.data.typeId,
    };
    notifications.push(data);
    store.commit("setNotifications", notifications);
    store.dispatch("AUTH_NOTIFICATION", data);
    store.state.toast.add({
      severity: "info",
      summary: "",
      detail: data.title,
      life: 5000,
    });
  });

  return { getDevice };
};
