import { VisitService } from "../services";

export const visit = {
  state: () => ({
    visits: [],
    selectedVisits: [],
    visitApartments: [],
  }),
  mutations: {
    setVisits: (state, value) => (state.visits = value),
    setSelectedVisits: (state, value) => (state.selectedVisits = value),
    setVisitApartments: (state, value) => (state.visitApartments = value),
  },
  actions: {
    VISIT_FIND_ALL: async ({ commit }, payload) => {
      const response = await VisitService.findAll(payload);
      if (response.ok) {
        commit("setVisits", response.visits);
      } else {
        alert(response.message);
      }
    },
    VISIT_APARTMENTS: async ({ commit }, payload) => {
      const response = await VisitService.apartments(payload);
      if (response.ok) {
        commit("setVisitApartments", response.apartments);
      } else {
        alert(response.message);
      }
    },
    VISIT_CREATE: async (_, payload) => await VisitService.create(payload),
    VISIT_STATUS: async (_, payload) => await VisitService.status(payload),
    VISIT_REMOVE: async (_, payload) => await VisitService.remove(payload),
  },
  getters: {},
};
