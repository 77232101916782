<template>
  <div class="content">
    <BackButton v-if="store.state.general.breadcrumbs.length" />
    <div class="container">
      <div
        v-for="item in store.state.general.breadcrumbs"
        :key="item.to"
        class="breadcrumb"
      >
        <router-link :to="item.to" class="breadcrumb-item">{{
          item.label
        }}</router-link>
        <i class="pi pi-angle-right breadcrumb-separator"></i>
      </div>
    </div>
  </div>
</template>

<script>
import store from "../../store";
import BackButton from "../button/BackButton.vue";

export default {
  components: { BackButton },
  setup() {
    return { store };
  },
};
</script>

<style scoped>
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.container {
  padding: 1rem;
  display: flex;
  justify-content: center;
}
.breadcrumb {
  display: flex;
  align-items: center;
  justify-content: center;
}
.breadcrumb-item {
  color: var(--primary-dark);
  text-decoration: none;
  font-size: 0.8rem;
}
.breadcrumb-item:hover {
  color: var(--primary);
}
.breadcrumb-separator {
  color: var(--primary-dark);
  font-size: 0.8rem;
}
</style>
