import { PantryService } from "../services";

export const pantry = {
  state: () => ({
    pantries: [],
    selectedPantries: [],
  }),
  mutations: {
    setPantries: (state, value) => (state.pantries = value),
    setSelectedPantries: (state, value) => (state.selectedPantries = value),
  },
  actions: {
    PANTRY_FIND_ALL: async ({ commit }, payload) => {
      const response = await PantryService.findAll(payload);
      if (response.ok) {
        commit("setPantries", response.pantries);
      } else {
        alert(response.message);
      }
    },
    PANTRY_CREATE: async (_, payload) => await PantryService.create(payload),
    PANTRY_UPDATE: async (_, payload) => await PantryService.update(payload),
    PANTRY_DELETE: async (_, payload) => await PantryService.remove(payload),
  },
  getters: {},
};
