import { VotingService } from "../services";

export const voting = {
  state: () => ({
    votings: [],
    selectedVotings: [],
    details: [],
    selectedDetails: [],
  }),
  mutations: {
    setVotings: (state, value) => (state.votings = value),
    setSelectedVotings: (state, value) => (state.selectedVotings = value),
    setDetails: (state, value) => (state.details = value),
    setSelectedDetails: (state, value) => (state.selectedDetails = value),
  },
  actions: {
    VOTING_FIND_ALL: async ({ commit }, payload) => {
      const response = await VotingService.findAll(payload);
      if (response.ok) {
        commit("setVotings", response.votings);
      } else {
        alert(response.message);
      }
    },
    VOTING_CREATE: async (_, payload) => await VotingService.create(payload),
    VOTING_DELETE: async (_, payload) => await VotingService.remove(payload),
    VOTING_DETAIL_FIND_ALL: async ({ commit }, payload) => {
      const response = await VotingService.findAllDetail(payload);
      if (response.ok) {
        commit("setDetails", response.voting.details);
      } else {
        alert(response.message);
      }
    },
    VOTING_DETAIL_CREATE: async (_, payload) =>
      await VotingService.createDetail(payload),
    VOTING_DETAIL_UPDATE: async (_, payload) =>
      await VotingService.updateDetail(payload),
    VOTING_DETAIL_DELETE: async (_, payload) =>
      await VotingService.removeDetail(payload),
    VOTING_CHANGE_STATUS: async (_, payload) =>
      await VotingService.changeStatus(payload),
  },
  getters: {},
};
