import { CompanyService } from "../services";

export const company = {
  state: () => ({
    companies: [],
    selectedCompanies: [],
  }),
  mutations: {
    setCompanies: (state, value) => (state.companies = value),
    setSelectedCompanies: (state, value) => (state.selectedCompanies = value),
  },
  actions: {
    COMPANY_FIND_ALL: async ({ commit }) => {
      const response = await CompanyService.findAll();
      if (response.ok) {
        commit("setCompanies", response.companies);
      } else {
        alert(response.message);
      }
    },
    COMPANY_CREATE: async (_, payload) => await CompanyService.create(payload),
    COMPANY_UPDATE: async (_, payload) => await CompanyService.update(payload),
    COMPANY_DELETE: async (_, payload) => await CompanyService.remove(payload),
  },
  getters: {},
};
