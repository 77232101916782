import { PersonnelService } from "../services";

export const personnel = {
  state: () => ({
    personnelList: [],
    selectedPersonnelList: [],
    workday: [],
    selectedWorkday: [],
    records: [],
    licenseTypeList: [],
    selectedLicenseTypeList: [],
  }),
  mutations: {
    setPersonnelList: (state, value) => (state.personnelList = value),
    setSelectedPersonnelList: (state, value) =>
      (state.selectedPersonnelList = value),
    setWorkday: (state, value) => (state.workday = value),
    setSelectedWorkday: (state, value) => (state.selectedWorkday = value),
    setRecords: (state, value) => (state.records = value),
    setLicenseTypeList: (state, value) => (state.licenseTypeList = value),
    setSelectedLicenseTypeList: (state, value) =>
      (state.selectedLicenseTypeList = value),
  },
  actions: {
    PERSONNEL_FIND_ALL: async ({ commit }, payload) => {
      const response = await PersonnelService.findAll(payload);
      if (response.ok) {
        commit("setPersonnelList", response.personnelList);
      } else {
        alert(response.message);
      }
    },
    PERSONNEL_CREATE: async (_, payload) =>
      await PersonnelService.create(payload),
    PERSONNEL_UPDATE: async (_, payload) =>
      await PersonnelService.update(payload),
    PERSONNEL_CHANGE_STATUS: async (_, payload) =>
      await PersonnelService.status(payload),
    PERSONNEL_RECORDS: async ({ commit }, payload) => {
      const response = await PersonnelService.records(payload);
      if (response.ok) {
        commit("setRecords", response.records);
      } else {
        alert(response.message);
      }
    },
    PERSONNEL_CREATE_LICENSE_TYPE: async (_, payload) =>
      await PersonnelService.createLicenseType(payload),
    PERSONNEL_DELETE_LICENSE_TYPE: async (_, payload) =>
      await PersonnelService.deleteLicenseType(payload),
    PERSONNEL_FIND_ALL_LICENSE_TYPE: async ({ commit }, payload) => {
      const response = await PersonnelService.findAllLicenseType(payload);
      if (response.ok) {
        commit("setLicenseTypeList", response.licenseTypeList);
      } else {
        alert(response.message);
      }
    },
  },
  getters: {},
};
