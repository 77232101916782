import { tuApptoAPI } from "./config";

export const remove = async (request) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.post(
      "/notifications/admin/delete",
      request,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
