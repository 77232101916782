const fields = [
  "prevBalance",
  "payments",
  "balance",
  "surcharge",
  "commonExpenses",
  "extraFee",
  "reserveFound",
  "commonAreas",
  "totalPeriod",
  "totalDebt",
  "income",
  "expense",
  "total",
];

export const convertNumberToCurrency = (number = 0) => {
  const n = number.toFixed(2);
  const currency = `$${new Intl.NumberFormat("de-DE").format(n)}`;
  const arr = currency.split(",");
  if (arr.length === 1) return `${currency},00`;
  else if (+arr[1] < 10) return `${currency}0`;
  return currency;
};
export const convertCurrencyToNumber = (currency) => {
  return Number(
    currency
      .split("$")[1]
      .split(".")
      .join("")
      .split(",")
      .join(".")
  );
};

export const isFieldCurrency = (field) => fields.includes(field);
