export const menu = [
  {
    path: "/administradoras",
    name: "Administradoras",
    component: () => import("../views/menu/Companies.vue"),
  },
  {
    path: "/complejos",
    name: "Complejos",
    component: () => import("../views/menu/Buildings.vue"),
  },
  {
    path: "/usuarios",
    name: "Usuarios",
    component: () => import("../views/menu/Users.vue"),
  },
];

export const routes = [
  /** Administradoras */
  {
    path: "/administradoras/listado",
    name: "Listado de Administradoras",
    component: () => import("../views/company/Companies.vue"),
  },
  {
    path: "/administradoras/nuevo",
    name: "Alta de Administradora",
    component: () => import("../views/company/Company.vue"),
  },
  {
    path: "/administradoras/modificar",
    name: "Modificación de Administradora",
    component: () => import("../views/company/Company.vue"),
  },
  /** Complejos */
  {
    path: "/complejos/listado",
    name: "Listado de Complejos",
    component: () => import("../views/building/Buildings.vue"),
  },
  {
    path: "/complejos/nuevo",
    name: "Alta de Complejo",
    component: () => import("../views/building/Building.vue"),
  },
  {
    path: "/complejos/modificar",
    name: "Modificación de Complejo",
    component: () => import("../views/building/Building.vue"),
  },
  {
    path: "/complejos/torres/asociar",
    name: "Asociar Torres",
    component: () => import("../views/building/Towers.vue"),
  },
  /** Usuarios */
  {
    path: "/usuarios/listado",
    name: "Listado de Usuarios",
    component: () => import("../views/user/Users.vue"),
  },
  {
    path: "/usuarios/nuevo",
    name: "Alta de Usuario",
    component: () => import("../views/user/User.vue"),
  },
  {
    path: "/usuarios/modificar",
    name: "Modificación de Usuario",
    component: () => import("../views/user/User.vue"),
  },
];
