export const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

export const dateToString = (date = null) => {
  let tempDate = new Date();
  if (date) tempDate = new Date(date);

  let day = tempDate.getDate();
  if (day < 10) day = `0${day}`;
  let month = tempDate.getMonth() + 1;
  if (month < 10) month = `0${month}`;
  const year = tempDate.getFullYear();

  return `${day}/${month}/${year}`;
};

export const timeToString = (date = null) => {
  let tempDate = new Date();
  if (date) tempDate = new Date(date);

  let hours = tempDate.getHours();
  if (hours < 10) hours = `0${hours}`;
  let minutes = tempDate.getMinutes();
  if (minutes < 10) minutes = `0${minutes}`;

  return `${hours}:${minutes}`;
};

export const getPeriodToString = (date = null) => {
  let tempDate = new Date();
  if (date) tempDate = new Date(date);

  const year = tempDate.getFullYear();
  const month = months[tempDate.getMonth()];

  return `${month} ${year}`;
};

export const convertStringToDate = (str = "1/1/2021") => {
  const tempStr = str.split("/");
  const tempDate = new Date();
  tempDate.setDate(Number(tempStr[0]));
  tempDate.setMonth(Number(tempStr[1]) - 1);
  tempDate.setFullYear(Number(tempStr[2]));
  return new Date(tempDate).getTime();
};
