import { MaintenanceService } from "../services";

export const maintenance = {
  state: () => ({
    maintenances: [],
    selectedMaintenances: [],
    disabledDays: [],
  }),
  mutations: {
    setMaintenances: (state, value) => (state.maintenances = value),
    setSelectedMaintenances: (state, value) =>
      (state.selectedMaintenances = value),
  },
  actions: {
    MAINTENANCE_ALL: async ({ commit }, payload) => {
      const response = await MaintenanceService.findAll(payload);
      if (response.ok) {
        commit("setMaintenances", response.maintenances);
      } else {
        alert(response.message);
      }
    },
    MAINTENANCE_CREATE: async (_, payload) =>
      await MaintenanceService.create(payload),
    MAINTENANCE_SET_STATUS: async (_, payload) =>
      await MaintenanceService.status(payload),
    MAINTENANCE_AVAILABLE_TURNS: async (_, payload) =>
      await MaintenanceService.available(payload),
    MAINTENANCE_DISABLED_DAYS: async ({ commit }, payload) => {
      const response = await MaintenanceService.disabled(payload);
      if (response.ok) {
        commit("setDisabledDays", response.disabledDays);
      } else {
        alert(response.message);
      }
    },
  },
  getters: {},
};
