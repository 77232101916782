import { tuApptoAPI } from "./config";

export const general = async () => {
  try {
    const response = await tuApptoAPI.get("/general/data");
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const companies = async (id) => {
  try {
    const response = await tuApptoAPI.get(`/general/companies/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const buildings = async (id) => {
  try {
    const response = await tuApptoAPI.get(`/general/buildings/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const roles = async (id) => {
  try {
    const response = await tuApptoAPI.get(`/general/roles/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const apartments = async (request) => {
  try {
    const response = await tuApptoAPI.post(
      `/general/apartments/${request.buildingId}`,
      request
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const commonareas = async (id) => {
  try {
    const response = await tuApptoAPI.get(`/general/commonareas/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const frequency = async (id) => {
  try {
    const response = await tuApptoAPI.get("/general/frequency");
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const personnelType = async (id) => {
  try {
    const response = await tuApptoAPI.get("/general/personnel/type");
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getSignedUrl = async (request) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.post("/general/getsignedurl", request, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
