import { CommonAreaService } from "../services";

export const commonArea = {
  state: () => ({
    commonAreas: [],
    selectedCommonAreas: [],
    turns: [],
    selectedTurns: [],
  }),
  mutations: {
    setCommonAreas: (state, value) => (state.commonAreas = value),
    setSelectedCommonAreas: (state, value) =>
      (state.selectedCommonAreas = value),
    setTurns: (state, value) => (state.turns = value),
    setSelectedTurns: (state, value) => (state.selectedTurns = value),
  },
  actions: {
    COMMON_AREA_FIND_ALL: async ({ commit }, payload) => {
      const response = await CommonAreaService.findAll(payload);
      if (response.ok) {
        commit("setCommonAreas", response.commonAreas);
      } else {
        alert(response.message);
      }
    },
    COMMON_AREA_CREATE: async (_, payload) =>
      await CommonAreaService.create(payload),
    COMMON_AREA_UPDATE: async (_, payload) =>
      await CommonAreaService.update(payload),
    COMMON_AREA_DELETE: async (_, payload) =>
      await CommonAreaService.remove(payload),
  },
  getters: {},
};
