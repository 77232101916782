import { MessageService } from "../services";

export const message = {
  state: () => ({
    inbox: [],
    selectedInbox: [],
    send: [],
    selectedSend: [],
    categories: [],
    recipients: [],
    isSend: false,
    messages: [],
  }),
  mutations: {
    setInbox: (state, value) => (state.inbox = value),
    setSelectedInbox: (state, value) => (state.selectedInbox = value),
    setSend: (state, value) => (state.send = value),
    setSelectedSend: (state, value) => (state.selectedSend = value),
    setCategories: (state, value) => (state.categories = value),
    setRecipients: (state, value) => (state.recipients = value),
    setIsSend: (state, value) => (state.isSend = value),
    setMessages: (state, value) => (state.messages = value),
  },
  actions: {
    MESSAGE_INBOX: async ({ commit }, payload) => {
      const response = await MessageService.inbox(payload);
      if (response.ok) {
        const orderList = [];
        response.inbox.forEach((item) => {
          const finded = orderList.find(
            (el) => el.messageParentId === item.message.messageParentId
          );

          if (!finded) {
            orderList.push({
              messageParentId: item.message.messageParentId,
              order: item.message.id,
            });
          } else if (finded.order < item.message.id) {
            orderList.splice(orderList.indexOf(finded), 1);
            orderList.push({
              messageParentId: item.message.messageParentId,
              order: item.message.id,
            });
          }
        });

        const inbox = response.inbox.map((item) => {
          const finded = orderList.find(
            (el) => el.messageParentId === item.message.messageParentId
          );
          if (finded) return { ...item, order: finded.order };
          else return { ...item, order: 0 };
        });
        commit("setInbox", inbox);
      } else {
        alert(response.message);
      }
    },
    MESSAGE_SEND: async ({ commit }, payload) => {
      const response = await MessageService.send(payload);
      if (response.ok) {
        const orderList = [];
        response.send.forEach((item) => {
          const finded = orderList.find(
            (el) => el.messageParentId === item.message.messageParentId
          );

          if (!finded) {
            orderList.push({
              messageParentId: item.message.messageParentId,
              order: item.message.id,
            });
          } else if (finded.order < item.message.id) {
            orderList.splice(orderList.indexOf(finded), 1);
            orderList.push({
              messageParentId: item.message.messageParentId,
              order: item.message.id,
            });
          }
        });

        const send = response.send.map((item) => {
          const finded = orderList.find(
            (el) => el.messageParentId === item.message.messageParentId
          );
          if (finded) return { ...item, order: finded.order };
          else return { ...item, order: 0 };
        });
        commit("setSend", send);
      } else {
        alert(response.message);
      }
    },
    MESSAGE_CATEGORIES: async ({ commit }) => {
      const response = await MessageService.categories();
      if (response.ok) {
        commit("setCategories", response.categories);
      } else {
        alert(response.message);
      }
    },
    MESSAGE_RECIPIENTS: async ({ commit }, payload) => {
      const response = await MessageService.recipients(payload);
      if (response.ok) {
        commit("setRecipients", response.recipients);
      } else {
        alert(response.message);
      }
    },
    MESSAGE_CREATE: async (_, payload) => await MessageService.create(payload),
    MESSAGE_CHANGE_STATUS: async (_, payload) =>
      await MessageService.changeStatus(payload),
    MESSAGE_SEND_MESSAGE: async (_, payload) =>
      await MessageService.sendMessage(payload),
    MESSAGE_DETAIL: async ({ commit }, payload) => {
      const response = await MessageService.detail(payload);
      if (response.ok) {
        commit("setMessages", response.messages);
      } else {
        alert(response.message);
      }
    },
  },
  getters: {},
};
