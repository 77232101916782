import { tuApptoAPI } from "./config";

export const findAll = async (request) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.post("/assemblies/findall", request, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const create = async (request) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.post("/assemblies/create", request, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const remove = async (id) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.delete(`/assemblies/delete/${id}`, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const findAllResolution = async (request) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.post(
      "/assemblies/resolutions/findall",
      request,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const createResolution = async (request) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.post(
      "/assemblies/resolutions/create",
      request,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const updateResolution = async (request) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.put(
      "/assemblies/resolutions/update",
      request,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const removeAssembly = async (id) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.delete(
      `/assemblies/resolutions/delete/${id}`,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
