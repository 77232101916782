<template>
  <div class="header-menu">
    <div class="header-menu-user">
      <p v-if="store.state.auth.isAuth">Bienvenido, <strong>{{ store.state.auth.user.name }}</strong></p>
    </div>
    <i
      @click="toggle"
      aria-haspopup="true"
      aria-controls="overlay_menu"
      class="pi pi-bars"
    ></i>
    <Menu id="overlay_menu" ref="menu" :model="items" :popup="true" />
  </div>
</template>

<script>
import { computed, ref } from "vue";
import store from '../../store'
import * as constants from '../../constants'
import {useRouter} from 'vue-router'

export default {
  setup() {
    const router = useRouter()
    const menu = ref(null);
    const toggle = (event) => menu.value.toggle(event);
    const items = computed(() => {
      if(store.state.auth.isAuth) {
        let routes = []
        const configuration = []
        const menuBar = [
          { label: 'Inicio', to: '/' },
        ]

        if(store.state.auth.role.name !== constants.roles.master) {
          routes = store.state.auth.menu.map(item => ({
            label: item.label,
            command: () => {
              if(store.state.general.selectedBuilding) {
                router.push(item.to)
              } else {
                alert('Debe seleccionar un complejo!')
              }
            }
          }))
        } else {
          routes = store.state.auth.menu
        }

        if(routes.length) {
          menuBar.push({ separator: true })
          menuBar.push({ label: 'Módulos', items: routes })
        }

        if(store.state.auth.role.name !== constants.roles.master) {
          constants.menu.configurationMenu.forEach(item => {
            const finded = router.getRoutes().find(el => el.path === item.to)
            if(finded) configuration.push(item)
          })
        }

        if(configuration.length) {
          menuBar.push({ separator: true })
          menuBar.push({ label: 'Configuración', items: configuration })
        }

        menuBar.push({ separator: true })
        menuBar.push({ label: 'Cuenta', to: '/account' })
        menuBar.push({ separator: true })
        menuBar.push({ label: 'Cerrar Sesión', command: () => {
          sessionStorage.clear()
          location.href = '/login'
        } })

        return menuBar
      } else {
        return store.state.auth.menu
      }
    })

    return { store, menu, toggle, items };
  },
}
</script>

<style scoped>
.header-menu {
  display: flex;
  align-items: center;
}
.header-menu-user {
  display: flex;
  margin-right: .5rem;
}
.header-menu-user > p {
  color: var(--primary-dark);
}
.header-menu > i {
  font-size: 2rem;
  color: var(--primary);
  cursor: pointer;
}
@media screen and (min-width: 1200px) {
  .header-menu > i {
    color: white;
  }
  .header-menu-user > p {
    color: white;
  }
}
</style>