import { TowerService } from "../services";

export const tower = {
  state: () => ({
    towers: [],
    selectedTowers: [],
  }),
  mutations: {
    setTowers: (state, value) => (state.towers = value),
    setSelectedTowers: (state, value) => (state.selectedTowers = value),
  },
  actions: {
    TOWER_FIND_ALL: async ({ commit }, payload) => {
      const response = await TowerService.findAll(payload);
      if (response.ok) {
        commit("setTowers", response.towers);
      } else {
        alert(response.message);
      }
    },
    TOWER_CREATE: async (_, payload) => await TowerService.create(payload),
    TOWER_UPDATE: async (_, payload) => await TowerService.update(payload),
    TOWER_DELETE: async (_, payload) => await TowerService.remove(payload),
  },
  getters: {},
};
