export const menu = [
  // {
  //   path: "/personal",
  //   name: "Personal",
  //   component: () => import("../views/menu/Personal.vue"),
  // },
  {
    path: "/complejo",
    name: "Complejo",
    component: () => import("../views/menu/Building.vue"),
  },
  {
    path: "/gastos-comunes",
    name: "Gastos Comunes",
    component: () => import("../views/menu/CommonExpenses.vue"),
  },
  {
    path: "/asambleas",
    name: "Asambleas",
    component: () => import("../views/menu/Assemblies.vue"),
  },
  {
    path: "/mensajes",
    name: "Mensajería",
    component: () => import("../views/menu/Messages.vue"),
  },
  {
    path: "/personal",
    name: "Personal",
    component: () => import("../views/menu/Personnel.vue"),
  },
  {
    path: "/porteria",
    name: "Portería",
    component: () => import("../views/menu/Reception.vue"),
  },
];

export const routes = [
  /** Complejos */
  {
    path: "/complejos/listado",
    name: "Listado de Complejos",
    component: () => import("../views/building/Buildings.vue"),
  },
  {
    path: "/complejos/nuevo",
    name: "Alta de Complejo",
    component: () => import("../views/building/Building.vue"),
  },
  {
    path: "/complejos/modificar",
    name: "Modificación de Complejo",
    component: () => import("../views/building/Building.vue"),
  },
  {
    path: "/complejos/torres/asociar",
    name: "Asociar Torres",
    component: () => import("../views/building/Towers.vue"),
  },
  /** Perfiles */
  {
    path: "/perfiles/listado",
    name: "Listado de Perfiles",
    component: () => import("../views/role/Roles.vue"),
  },
  {
    path: "/perfiles/nuevo",
    name: "Alta de Perfil",
    component: () => import("../views/role/Role.vue"),
  },
  {
    path: "/perfiles/modificar",
    name: "Modificación de Perfil",
    component: () => import("../views/role/Role.vue"),
  },
  /** Empleados */
  {
    path: "/empleados/listado",
    name: "Listado de Empleados",
    component: () => import("../views/employee/Employees.vue"),
  },
  {
    path: "/empleados/nuevo",
    name: "Alta de Empleado",
    component: () => import("../views/employee/Employee.vue"),
  },
  {
    path: "/empleados/modificar",
    name: "Modificación de Empleado",
    component: () => import("../views/employee/Employee.vue"),
  },
  /** Residentes */
  {
    path: "/residentes/listado",
    name: "Listado de Residentes",
    component: () => import("../views/resident/Residents.vue"),
  },
  {
    path: "/residentes/nuevo",
    name: "Alta de Residente",
    component: () => import("../views/resident/Resident.vue"),
  },
  {
    path: "/residentes/modificar",
    name: "Modificación de Residente",
    component: () => import("../views/resident/Resident.vue"),
  },
  {
    path: "/residentes/apartamentos/asociar",
    name: "Asociar Apartamentos",
    component: () => import("../views/resident/Associate.vue"),
  },
  /** Apartamentos */
  {
    path: "/apartamentos/listado",
    name: "Listado de Apartamentos",
    component: () => import("../views/apartment/Apartments.vue"),
  },
  {
    path: "/apartamentos/nuevo",
    name: "Alta de Apartamento",
    component: () => import("../views/apartment/Apartment.vue"),
  },
  {
    path: "/apartamentos/modificar",
    name: "Modificación de Apartamento",
    component: () => import("../views/apartment/Apartment.vue"),
  },
  /** Despensas */
  {
    path: "/despensas/listado",
    name: "Listado de Despensas",
    component: () => import("../views/pantry/Pantries.vue"),
  },
  {
    path: "/despensas/nuevo",
    name: "Alta de Despensa",
    component: () => import("../views/pantry/Pantry.vue"),
  },
  {
    path: "/despensas/modificar",
    name: "Modificación de Despensa",
    component: () => import("../views/pantry/Pantry.vue"),
  },
  /** Estacionamientos */
  {
    path: "/estacionamientos/listado",
    name: "Listado de Estacionamientos",
    component: () => import("../views/parking/Parkings.vue"),
  },
  {
    path: "/estacionamientos/nuevo",
    name: "Alta de Estacionamiento",
    component: () => import("../views/parking/Parking.vue"),
  },
  {
    path: "/estacionamientos/modificar",
    name: "Modificación de Estacionamiento",
    component: () => import("../views/parking/Parking.vue"),
  },
  /** Áreas Comunes */
  {
    path: "/areascomunes/listado",
    name: "Listado de Áreas Comunes",
    component: () => import("../views/commonarea/CommonAreas.vue"),
  },
  {
    path: "/areascomunes/nuevo",
    name: "Alta de Área Común",
    component: () => import("../views/commonarea/CommonArea.vue"),
  },
  {
    path: "/areascomunes/modificar",
    name: "Modificación de Área Común",
    component: () => import("../views/commonarea/CommonArea.vue"),
  },
  /** Reservas */
  {
    path: "/reservas/listado",
    name: "Listado de Reservas",
    component: () => import("../views/reserve/Reserves.vue"),
  },
  {
    path: "/reservas/nuevo",
    name: "Alta de Reserva",
    component: () => import("../views/reserve/Reserve.vue"),
  },
  {
    path: "/reservas/modificar",
    name: "Modificación de Reserva",
    component: () => import("../views/reserve/Reserve.vue"),
  },
  /** Mantenimientos */
  {
    path: "/mantenimientos/listado",
    name: "Listado de Mantenimientos",
    component: () => import("../views/maintenance/Maintenances.vue"),
  },
  {
    path: "/mantenimientos/nuevo",
    name: "Alta de Mantenimiento",
    component: () => import("../views/maintenance/Maintenance.vue"),
  },
  {
    path: "/mantenimientos/modificar",
    name: "Modificación de Mantenimiento",
    component: () => import("../views/maintenance/Maintenance.vue"),
  },
  /** Gasto Común */
  {
    path: "/gastoscomunes/listado",
    name: "Listado de Gastos Comunes",
    component: () => import("../views/commonexpense/CommonExpenses.vue"),
  },
  {
    path: "/gastoscomunes/detalle",
    name: "Detalle de Gasto Común",
    component: () => import("../views/commonexpense/CommonExpense.vue"),
  },
  /** Cierres */
  {
    path: "/cierres/listado",
    name: "Listado de Cierres",
    component: () => import("../views/closure/Closures.vue"),
  },
  {
    path: "/cierres/nuevo",
    name: "Alta de Cierre",
    component: () => import("../views/closure/Closure.vue"),
  },
  /** Asambleas */
  {
    path: "/asambleas/listado",
    name: "Listado de Asambleas",
    component: () => import("../views/assembly/Assemblies.vue"),
  },
  {
    path: "/asambleas/nuevo",
    name: "Alta de Asamblea",
    component: () => import("../views/assembly/Assembly.vue"),
  },
  {
    path: "/asambleas/resoluciones/asociar",
    name: "Asociar Resoluciones",
    component: () => import("../views/assembly/Resolutions.vue"),
  },
  /** Votaciones */
  {
    path: "/votaciones/listado",
    name: "Listado de Votaciones",
    component: () => import("../views/voting/Votings.vue"),
  },
  {
    path: "/votaciones/nuevo",
    name: "Alta de Votación",
    component: () => import("../views/voting/Voting.vue"),
  },
  {
    path: "/votaciones/detalle/asociar",
    name: "Asociar Detalle de Votación",
    component: () => import("../views/voting/Detail.vue"),
  },
  /** Noticias */
  {
    path: "/noticias/listado",
    name: "Listado de Noticias",
    component: () => import("../views/notice/Notices.vue"),
  },
  {
    path: "/noticias/nuevo",
    name: "Alta de Noticia",
    component: () => import("../views/notice/Notice.vue"),
  },
  /** Documentos */
  {
    path: "/documentos/listado",
    name: "Listado de Documentos",
    component: () => import("../views/document/Documents.vue"),
  },
  /** Mensajes */
  {
    path: "/mensajes/listado",
    name: "Listado de Mensajes",
    component: () => import("../views/message/Messages.vue"),
  },
  {
    path: "/mensajes/nuevo",
    name: "Alta de Mensaje",
    component: () => import("../views/message/Message.vue"),
  },
  {
    path: "/mensajes/chat",
    name: "Chat de Mensaje",
    component: () => import("../views/message/Chat.vue"),
  },
  /** Personal */
  {
    path: "/personal/listado",
    name: "Listado de Personal",
    component: () => import("../views/personnel/PersonnelList.vue"),
  },
  {
    path: "/personal/nuevo",
    name: "Alta de Personal",
    component: () => import("../views/personnel/Personnel.vue"),
  },
  {
    path: "/personal/modificar",
    name: "Modificación de Personal",
    component: () => import("../views/personnel/Personnel.vue"),
  },
  /** Registro de Horario */
  {
    path: "/horario/listado",
    name: "Listado de Registro de Horario",
    component: () => import("../views/timerecord/TimeRecordList.vue"),
  },
  {
    path: "/horario/licencias/listado",
    name: "Alta de Tipo de Licencia",
    component: () => import("../views/timerecord/LicenseTypeList.vue"),
  },
  /** Contratos */
  {
    path: "/contratos/listado",
    name: "Listado de Contratos",
    component: () => import("../views/contract/ContractList.vue"),
  },
  {
    path: "/contratos/nuevo",
    name: "Alta de Contrato",
    component: () => import("../views/contract/Contract.vue"),
  },
  /** Desvinculaciones */
  {
    path: "/desvinculaciones/listado",
    name: "Listado de Desvinculaciones",
    component: () => import("../views/unlink/UnlinkList.vue"),
  },
  {
    path: "/desvinculaciones/nuevo",
    name: "Alta de Desvinculación",
    component: () => import("../views/unlink/Unlink.vue"),
  },
  /** Licencias */
  {
    path: "/licencias/listado",
    name: "Listado de Licencias",
    component: () => import("../views/license/LicenseList.vue"),
  },
  {
    path: "/licencias/nuevo",
    name: "Alta de Licencia",
    component: () => import("../views/license/License.vue"),
  },
  /** Encomiendas */
  {
    path: "/encomiendas/listado",
    name: "Listado de Encomiendas",
    component: () => import("../views/shipment/ShipmentList.vue"),
  },
  {
    path: "/encomiendas/nuevo",
    name: "Alta de Encomienda",
    component: () => import("../views/shipment/Shipment.vue"),
  },
  /** Visitas */
  {
    path: "/visitas/listado",
    name: "Listado de Visitas",
    component: () => import("../views/visit/VisitList.vue"),
  },
  {
    path: "/visitas/nuevo",
    name: "Alta de Visita",
    component: () => import("../views/visit/Visit.vue"),
  },
  /** Reportes */
  {
    path: "/reportes/listado",
    name: "Listado de Reportes",
    component: () => import("../views/report/ReportList.vue"),
  },
  {
    path: "/reportes/nuevo",
    name: "Alta de Reporte",
    component: () => import("../views/report/Report.vue"),
  },
];
