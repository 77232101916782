import { NoticeService } from "../services";

export const notice = {
  state: () => ({
    notices: [],
    selectedNotices: [],
  }),
  mutations: {
    setNotices: (state, value) => (state.notices = value),
    setSelectedNotices: (state, value) => (state.selectedNotices = value),
  },
  actions: {
    NOTICE_FIND_ALL: async ({ commit }, payload) => {
      const response = await NoticeService.findAll(payload);
      if (response.ok) {
        commit("setNotices", response.notices);
      } else {
        alert(response.message);
      }
    },
    NOTICE_CREATE: async (_, payload) => await NoticeService.create(payload),
    NOTICE_DELETE: async (_, payload) => await NoticeService.remove(payload),
  },
  getters: {},
};
