import { CommonExpenseService } from "../services";

export const commonExpense = {
  state: () => ({
    commonExpenses: [],
    commonExpenseDetail: [],
    selectedCommonExpenses: [],
    selectedCommonExpenseDetail: [],
    closures: [],
    selectedClosures: [],
  }),
  mutations: {
    setCommonExpenses: (state, value) => (state.commonExpenses = value),
    setSelectedCommonExpenses: (state, value) =>
      (state.selectedCommonExpenses = value),
    setCommonExpenseDetail: (state, value) =>
      (state.commonExpenseDetail = value),
    setSelectedCommonExpenseDetail: (state, value) =>
      (state.selectedCommonExpenseDetail = value),
    setClosures: (state, value) => (state.closures = value),
    setSelectedClosures: (state, value) => (state.selectedClosures = value),
  },
  actions: {
    COMMON_EXPENSE_ALL: async ({ commit }, payload) => {
      const response = await CommonExpenseService.findAll(payload);
      if (response.ok) {
        commit("setCommonExpenses", response.commonExpenses);
      } else {
        alert(response.message);
      }
    },
    COMMON_EXPENSE_DETAIL_ALL: async ({ commit }, payload) => {
      const response = await CommonExpenseService.findAllDetail(payload);
      if (response.ok) {
        commit("setCommonExpenseDetail", response.commonExpensesDetail);
      } else {
        alert(response.message);
      }
      return response;
    },
    COMMON_EXPENSE_CLOSURES_ALL: async ({ commit }, payload) => {
      const response = await CommonExpenseService.findAllClosures(payload);
      if (response.ok) {
        commit("setClosures", response.closures);
      } else {
        alert(response.message);
      }
      return response;
    },
    COMMON_EXPENSE_MOVEMENT_ALL: async (_, payload) =>
      await CommonExpenseService.findAllMovements(payload),
    COMMON_EXPENSE_CREATE: async (_, payload) =>
      await CommonExpenseService.create(payload),
    COMMON_EXPENSE_CREATE_DETAIL: async (_, payload) =>
      await CommonExpenseService.createDetail(payload),
    COMMON_EXPENSE_CREATE_MOVEMENT: async (_, payload) =>
      await CommonExpenseService.createMovement(payload),
    COMMON_EXPENSE_GENERATE: async (_, payload) =>
      await CommonExpenseService.generate(payload),
    COMMON_EXPENSE_SEND: async (_, payload) =>
      await CommonExpenseService.send(payload),
    COMMON_EXPENSE_DETAIL_TEMPLATE: async () =>
      await CommonExpenseService.detailTemplate(),
    COMMON_EXPENSE_MOVEMENT_TEMPLATE: async () =>
      await CommonExpenseService.movementTemplate(),
    COMMON_EXPENSE_DOWNLOAD_EXCEL: async (_, payload) =>
      await CommonExpenseService.commonExpensesXls(payload),
    COMMON_EXPENSE_CONFIRM: async (_, payload) =>
      await CommonExpenseService.confirm(payload),
    COMMON_EXPENSE_SAVE_DOCUMENT: async (_, payload) =>
      await CommonExpenseService.saveDocument(payload),
  },
  getters: {},
};
