<template>
  <main class="main">
    <Toast position="top-right" />
    <div
      class="container"
      :style="route.name !== 'Landing' && 'padding-bottom: 12rem;'"
    >
      <BasicBreadCrumb />
      <router-view />
    </div>
  </main>
</template>

<script>
import Toast from "primevue/toast";
import BasicBreadCrumb from "../../widgets/breadcrumb/BasicBreadCrumb.vue";
import { useRoute } from "vue-router";

export default {
  components: {
    Toast,
    BasicBreadCrumb,
  },
  setup() {
    const route = useRoute();
    return { route };
  },
};
</script>

<style scoped>
.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -2rem;
}
.container {
  width: 100%;
}
</style>
