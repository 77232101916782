import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import PrimeVue from "primevue/config";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import Textarea from "primevue/textarea";
import Menu from "primevue/menu";
import ProgressSpinner from "primevue/progressspinner";
import Sidebar from "primevue/sidebar";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Tree from "primevue/tree";
import Dialog from "primevue/dialog";
import Calendar from "primevue/calendar";
import Checkbox from "primevue/checkbox";
import Toast from "primevue/toast";
import FileUpload from "primevue/fileupload";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import MultiSelect from "primevue/multiselect";

import ToastService from "primevue/toastservice";

import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import './registerServiceWorker'

const app = createApp(App);
app.use(store);
app.use(router);

app.use(PrimeVue);
app.component("Button", Button);
app.component("InputText", InputText);
app.component("Dropdown", Dropdown);
app.component("Textarea", Textarea);
app.component("Menu", Menu);
app.component("ProgressSpinner", ProgressSpinner);
app.component("Sidebar", Sidebar);
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("Tree", Tree);
app.component("Dialog", Dialog);
app.component("Calendar", Calendar);
app.component("Checkbox", Checkbox);
app.component("Toast", Toast);
app.component("FileUpload", FileUpload);
app.component("TabView", TabView);
app.component("TabPanel", TabPanel);
app.component("MultiSelect", MultiSelect);

app.use(ToastService);

app.mount("#app");
