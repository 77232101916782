import { tuApptoAPI } from "./config";

export const findAll = async (id) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.get(`/reserves/findall/${id}`, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const historic = async (id) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.get(`/reserves/historic/${id}`, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const disabled = async (request) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.post("/reserves/disabled", request, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const turns = async (request) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.post(
      "/reserves/turns/available",
      request,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const create = async (request) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.post("/reserves/create", request, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const status = async (request) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.post("/reserves/status", request, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const remove = async (id) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.delete(`/reserves/delete/${id}`, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
