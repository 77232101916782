export const building = [
  {
    notificationTypeId: -1,
    label: "Apartamentos",
    to: "/apartamentos/listado",
  },
  { notificationTypeId: -1, label: "Despensas", to: "/despensas/listado" },
  {
    notificationTypeId: -1,
    label: "Estacionamientos",
    to: "/estacionamientos/listado",
  },
  { notificationTypeId: -1, label: "Residentes", to: "/residentes/listado" },
  {
    notificationTypeId: -1,
    label: "Áreas Comunes",
    to: "/areascomunes/listado",
  },
  { notificationTypeId: 1, label: "Reservas", to: "/reservas/listado" },
];

export const commonExpenses = [
  { notificationTypeId: -1, label: "Cierres", to: "/cierres/listado" },
  { notificationTypeId: -1, label: "Totales Mensuales", to: "" },
  {
    notificationTypeId: 2,
    label: "Gastos Comunes",
    to: "/gastoscomunes/listado",
  },
  { notificationTypeId: -1, label: "Deuda", to: "" },
];

export const assembly = [
  { notificationTypeId: 6, label: "Asambleas", to: "/asambleas/listado" },
  { notificationTypeId: -1, label: "Votaciones", to: "/votaciones/listado" },
  { notificationTypeId: -1, label: "Noticias", to: "/noticias/listado" },
  { notificationTypeId: -1, label: "Documentos", to: "/documentos/listado" },
];

export const message = [
  { notificationTypeId: 3, label: "Mensajes", to: "/mensajes/listado" },
];

export const personnel = [
  { notificationTypeId: -1, label: "Personal", to: "/personal/listado" },
  {
    notificationTypeId: -1,
    label: "Registro de Horario",
    to: "/horario/listado",
  },
  {
    notificationTypeId: -1,
    label: "Contratos",
    to: "/contratos/listado",
  },
  {
    notificationTypeId: -1,
    label: "Desvinculaciones",
    to: "/desvinculaciones/listado",
  },
  {
    notificationTypeId: 8,
    label: "Licencias",
    to: "/licencias/listado",
  },
];

export const reception = [
  { notificationTypeId: -1, label: "Encomiendas", to: "/encomiendas/listado" },
  {
    notificationTypeId: -1,
    label: "Visitas",
    to: "/visitas/listado",
  },
  {
    notificationTypeId: -1,
    label: "Reportes",
    to: "/reportes/listado",
  },
  {
    notificationTypeId: -1,
    label: "Mantenimientos",
    to: "/mantenimientos/listado",
  },
];
