<template>
  <div class="container">
    <ProgressSpinner class="spinner" />
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--primary-light);
  opacity: 0.5;
  z-index: 9999999;
  display: flex;
  justify-content: center;
}
.spinner {
  position: fixed;
  top: 20rem;
}
</style>
