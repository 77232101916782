import { createRouter, createWebHistory } from "vue-router";
// import store from "../store";

const routes = [
  {
    path: "/home",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/account",
    name: "Account",
    component: () => import("../views/Account.vue"),
  },
  {
    path: "/",
    name: "Landing",
    component: () => import("../views/Landing.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/auth/Login.vue"),
  },
  {
    path: "/forgot",
    name: "Forgot",
    component: () => import("../views/auth/Forgot.vue"),
  },
  {
    path: "/access",
    name: "Access",
    component: () => import("../views/auth/Access.vue"),
  },
  {
    path: "/validate",
    name: "Validate",
    component: () => import("../views/auth/Validate.vue"),
  },
  {
    path: "/change",
    name: "Change",
    component: () => import("../views/auth/Change.vue"),
  },
  {
    path: "/policy",
    name: "Policy",
    component: () => import("../views/auth/Policy.vue"),
  },
  {
    path: "/terms",
    name: "Terms",
    component: () => import("../views/auth/Terms.vue"),
  },
];

routes.push({
  path: "/:catchAll(.*)",
  component: () => import("../views/NotFound.vue"),
});

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const user = sessionStorage.getItem("token");
  const authRoutes = [
    "Landing",
    "Login",
    "Access",
    "Policy",
    "Terms",
    "Forgot",
    "Validate",
  ];

  if (!authRoutes.includes(to.name) && !user) next({ name: "Landing" });
  else if (authRoutes.includes(to.name) && user) next({ name: from.name });
  else if (to.name !== "Change" && user && user.indCreated)
    next({ name: "Change" });
  else if (to.name === "Change" && user && !user.indCreated)
    next({ name: from.name });
  else next();
});

export default router;
