<template>
  <header class="header">
    <div class="header-role">{{ store.state.auth.role.description }}</div>
    <HeaderMenuMobile class="header-icon" />
  </header>
</template>

<script>
import { ref } from "vue";
import HeaderMenuMobile from "../menu/HeaderMenuMobile.vue";
import store from "../../store";

export default {
  components: { HeaderMenuMobile },
  setup() {
    return { store };
  },
};
</script>

<style scoped>
.header {
  height: 150px;
  margin-top: -1rem;
  background-image: url("../../assets/images/header.png");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.header-role {
  position: absolute;
  font-family: "klavika";
  font-size: 1.2rem;
  color: white;
  top: 1.8rem;
  left: 6rem;
}
.header-icon {
  position: absolute;
  bottom: 1rem;
  right: 2.5rem;
}
@media screen and (min-width: 769px) {
  .header-icon {
    bottom: 0;
  }
}
@media screen and (min-width: 993px) {
  .header-role {
    top: 2.6rem;
    left: 8.5rem;
  }
  .header-icon {
    bottom: 1.5rem;
    right: 2.5rem;
  }
  .header {
    height: 200px;
    margin-top: -2rem;
  }
}
@media screen and (min-width: 1201px) {
  .header {
    height: 250px;
    margin-top: -2rem;
  }
  .header-role {
    top: 3.5rem;
    left: 10.5rem;
  }
  .header-icon {
    bottom: 11rem;
    right: 2.5rem;
  }
}
@media screen and (min-width: 1441px) {
  .header {
    height: 280px;
    margin-top: -2rem;
  }
  .header-role {
    top: 4.2rem;
    left: 12.5rem;
  }
  .header-icon {
    bottom: 12rem;
    right: 6rem;
  }
}
@media screen and (min-width: 1881px) {
  .header {
    height: 350px;
    margin-top: -2rem;
  }
  .header-role {
    top: 5rem;
    left: 14.5rem;
  }
  .header-icon {
    bottom: 16rem;
    right: 3.5rem;
  }
}
</style>
