export const generalActions = {
  companies: "GENERAL_COMPANIES",
  roles: "GENERAL_ROLES",
  apartments: "GENERAL_APARTMENTS",
  commonAreas: "GENERAL_COMMON_AREAS",
  personnelType: "GENERAL_PERSONNEL_TYPE",
};

export const companyActions = {
  create: "COMPANY_CREATE",
  update: "COMPANY_UPDATE",
  delete: "COMPANY_DELETE",
  findAll: "COMPANY_FIND_ALL",
};

export const buildingActions = {
  create: "BUILDING_CREATE",
  update: "BUILDING_UPDATE",
  delete: "BUILDING_DELETE",
  findAll: "BUILDING_FIND_ALL",
};

export const towerActions = {
  create: "TOWER_CREATE",
  update: "TOWER_UPDATE",
  delete: "TOWER_DELETE",
  findAll: "TOWER_FIND_ALL",
};

export const apartmentActions = {
  create: "APARTMENT_CREATE",
  update: "APARTMENT_UPDATE",
  delete: "APARTMENT_DELETE",
  findAll: "APARTMENT_FIND_ALL",
};

export const pantryActions = {
  create: "PANTRY_CREATE",
  update: "PANTRY_UPDATE",
  delete: "PANTRY_DELETE",
  findAll: "PANTRY_FIND_ALL",
};

export const parkingActions = {
  create: "PARKING_CREATE",
  update: "PARKING_UPDATE",
  delete: "PARKING_DELETE",
  findAll: "PARKING_FIND_ALL",
};

export const userActions = {
  create: "USER_CREATE",
  update: "USER_UPDATE",
  status: "USER_CHANGE_STATUS",
  findAll: "USER_FIND_ALL",
};

export const rolesActions = {
  create: "ROLE_CREATE",
  update: "ROLE_UPDATE",
  findAll: "ROLE_FIND_ALL",
};

export const employeeActions = {
  create: "EMPLOYEE_CREATE",
  update: "EMPLOYEE_UPDATE",
  status: "EMPLOYEE_CHANGE_STATUS",
  findAll: "EMPLOYEE_FIND_ALL",
};

export const residentActions = {
  create: "RESIDENT_CREATE",
  apartments: "RESIDENT_FIND_ALL_APARTMENTS",
  status: "RESIDENT_CHANGE_STATUS",
  findAll: "RESIDENT_FIND_ALL",
  associate: "RESIDENT_ASSOCIATE",
  deassociate: "RESIDENT_DEASSOCIATE",
};

export const commonAreaActions = {
  create: "COMMON_AREA_CREATE",
  update: "COMMON_AREA_UPDATE",
  delete: "COMMON_AREA_DELETE",
  findAll: "COMMON_AREA_FIND_ALL",
};

export const reserveActions = {
  create: "RESERVE_CREATE",
  historic: "RESERVE_HISTORIC",
  delete: "RESERVE_DELETE",
  findAll: "RESERVE_FIND_ALL",
  days: "RESERVE_DISABLED_DAYS",
  turns: "RESERVE_AVAILABLE_TURNS",
  status: "RESERVE_CHANGE_STATUS",
};

export const commonExpenseActions = {
  create: "COMMON_EXPENSE_CREATE",
  createDetail: "COMMON_EXPENSE_CREATE_DETAIL",
  createMovement: "COMMON_EXPENSE_CREATE_MOVEMENT",
  generate: "COMMON_EXPENSE_GENERATE",
  send: "COMMON_EXPENSE_SEND",
  templateDetail: "COMMON_EXPENSE_DETAIL_TEMPLATE",
  templateMovement: "COMMON_EXPENSE_MOVEMENT_TEMPLATE",
  downloadExcel: "COMMON_EXPENSE_DOWNLOAD_EXCEL",
  findAllMovement: "COMMON_EXPENSE_MOVEMENT_ALL",
  findAllClosure: "COMMON_EXPENSE_CLOSURES_ALL",
  findAllDetail: "COMMON_EXPENSE_DETAIL_ALL",
  findAll: "COMMON_EXPENSE_ALL",
  confirm: "COMMON_EXPENSE_CONFIRM",
  saveDocument: "COMMON_EXPENSE_SAVE_DOCUMENT",
};

export const assemblyActions = {
  create: "ASSEMBLY_CREATE",
  delete: "ASSEMBLY_DELETE",
  findAll: "ASSEMBLY_FIND_ALL",
  findAllResolution: "ASSEMBLY_RESOLUTION_FIND_ALL",
  createResolution: "ASSEMBLY_RESOLUTION_CREATE",
  updateResolution: "ASSEMBLY_RESOLUTION_UPDATE",
  deleteResolution: "ASSEMBLY_RESOLUTION_DELETE",
};

export const votingActions = {
  create: "VOTING_CREATE",
  delete: "VOTING_DELETE",
  findAll: "VOTING_FIND_ALL",
  findAllDetail: "VOTING_DETAIL_FIND_ALL",
  createDetail: "VOTING_DETAIL_CREATE",
  updateDetail: "VOTING_DETAIL_UPDATE",
  deleteDetail: "VOTING_DETAIL_DELETE",
  changeStatus: "VOTING_CHANGE_STATUS",
};

export const noticeActions = {
  create: "NOTICE_CREATE",
  delete: "NOTICE_DELETE",
  findAll: "NOTICE_FIND_ALL",
};

export const documentActions = {
  download: "DOCUMENT_DOWNLOAD",
  upload: "DOCUMENT_UPLOAD",
  findAll: "DOCUMENT_FIND_ALL",
  getPath: "DOCUMENT_GET_PATH",
  categories: "DOCUMENT_CATEGORIES_FIND_ALL",
  createCategory: "DOCUMENT_CATEGORIES_CREATE",
  deleteCategory: "DOCUMENT_CATEGORIES_DELETE",
};

export const messageActions = {
  create: "MESSAGE_CREATE",
  inbox: "MESSAGE_INBOX",
  send: "MESSAGE_SEND",
  categories: "MESSAGE_CATEGORIES",
  recipients: "MESSAGE_RECIPIENTS",
  sendMessage: "MESSAGE_SEND_MESSAGE",
  detail: "MESSAGE_DETAIL",
  chageStatus: "MESSAGE_CHANGE_STATUS",
};

export const personnelActions = {
  create: "PERSONNEL_CREATE",
  update: "PERSONNEL_UPDATE",
  status: "PERSONNEL_CHANGE_STATUS",
  findAll: "PERSONNEL_FIND_ALL",
  findAllLicenseType: "PERSONNEL_FIND_ALL_LICENSE_TYPE",
  records: "PERSONNEL_RECORDS",
  createLicenseType: "PERSONNEL_CREATE_LICENSE_TYPE",
  deleteLicenseType: "PERSONNEL_DELETE_LICENSE_TYPE",
};

export const contractActions = {
  create: "CONTRACT_CREATE",
  findAll: "CONTRACT_FIND_ALL",
  status: "CONTRACT_CHANGE_STATUS",
};

export const unlinkActions = {
  create: "UNLINK_CREATE",
  findAll: "UNLINK_FIND_ALL",
};

export const licenseActions = {
  create: "LICENSE_CREATE",
  findAll: "LICENSE_FIND_ALL",
  status: "LICENSE_CHANGE_STATUS",
  type: "LICENSE_TYPE",
};

export const visitActions = {
  create: "VISIT_CREATE",
  findAll: "VISIT_FIND_ALL",
  status: "VISIT_STATUS",
  remove: "VISIT_REMOVE",
  apartments: "VISIT_APARTMENTS",
};

export const reportActions = {
  create: "REPORT_CREATE",
  findAll: "REPORT_FIND_ALL",
  status: "REPORT_STATUS",
  remove: "REPORT_REMOVE",
};

export const shipmentActions = {
  create: "SHIPMENT_CREATE",
  findAll: "SHIPMENT_FIND_ALL",
  status: "SHIPMENT_STATUS",
  remove: "SHIPMENT_REMOVE",
  type: "SHIPMENT_TYPE",
};
