import { BuildingService } from "../services";

export const building = {
  state: () => ({
    buildings: [],
    selectedBuildings: [],
  }),
  mutations: {
    setBuildings: (state, value) => (state.buildings = value),
    setSelectedBuildings: (state, value) => (state.selectedBuildings = value),
  },
  actions: {
    BUILDING_FIND_ALL: async ({ commit }, payload) => {
      const response = await BuildingService.findAll(payload);
      if (response.ok) {
        commit("setBuildings", response.buildings);
      } else {
        alert(response.message);
      }
    },
    BUILDING_CREATE: async (_, payload) =>
      await BuildingService.create(payload),
    BUILDING_UPDATE: async (_, payload) =>
      await BuildingService.update(payload),
    BUILDING_DELETE: async (_, payload) =>
      await BuildingService.remove(payload),
  },
  getters: {},
};
