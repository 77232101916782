import { UnlinkService } from "../services";

export const unlink = {
  state: () => ({
    unlinkList: [],
    selectedUnlinkList: [],
  }),
  mutations: {
    setUnlinkList: (state, value) => (state.unlinkList = value),
    setSelectedUnlinkList: (state, value) => (state.selectedUnlinkList = value),
  },
  actions: {
    UNLINK_FIND_ALL: async ({ commit }, payload) => {
      const response = await UnlinkService.findAll(payload);
      if (response.ok) {
        commit("setUnlinkList", response.unlinkList);
      } else {
        alert(response.message);
      }
    },
    UNLINK_CREATE: async (_, payload) => await UnlinkService.create(payload),
  },
  getters: {},
};
