import { tuApptoAPI } from './config'

export const signin = async (request) => {
  try {
    const response = await tuApptoAPI.post('/auth/signin', request)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export const data = async (request) => {
  const headers = {
    'x-token': sessionStorage.getItem('token')
  }
  try {
    const response = await tuApptoAPI.post('/auth/data', request, { headers })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export const updateUser = async (request) => {
  const headers = {
    'x-token': sessionStorage.getItem('token')
  }
  try {
    const response = await tuApptoAPI.post('/auth/user', request, { headers })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export const contact = async (request) => {
  try {
    const response = await tuApptoAPI.post('/auth/contact', request)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export const access = async (request) => {
  try {
    const response = await tuApptoAPI.post('/auth/access', request)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export const forgot = async (request) => {
  try {
    const response = await tuApptoAPI.post('/auth/forgot', request)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export const validate = async (request) => {
  try {
    const response = await tuApptoAPI.post('/auth/validate', request)
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export const change = async (request) => {
  const headers = {
    'x-token': sessionStorage.getItem('token')
  }
  try {
    const response = await tuApptoAPI.post('/auth/change', request, { headers })
    return response.data
  } catch (error) {
    return error.response.data
  }
}