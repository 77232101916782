import { DocumentService } from "../services";

export const document = {
  state: () => ({
    documents: [],
    selectedDocuments: [],
    categories: [],
  }),
  mutations: {
    setDocuments: (state, value) => (state.documents = value),
    setSelectedDocuments: (state, value) => (state.selectedDocuments = value),
    setCategories: (state, value) => (state.categories = value),
  },
  actions: {
    DOCUMENT_FIND_ALL: async ({ commit }, payload) => {
      const response = await DocumentService.findAll(payload);
      if (response.ok) {
        commit("setDocuments", response.documents);
      } else {
        alert(response.message);
      }
    },
    // DOCUMENT_DOWNLOAD: async (_, payload) => await DocumentService.create(payload),
    DOCUMENT_UPLOAD: async (_, payload) =>
      await DocumentService.upload(payload),
    DOCUMENT_GET_PATH: async (_, payload) =>
      await DocumentService.getPath(payload),
    DOCUMENT_CATEGORIES_FIND_ALL: async ({ commit }, payload) => {
      const response = await DocumentService.categories(payload);
      if (response.ok) {
        commit("setCategories", response.categories);
      } else {
        alert(response.message);
      }
    },
    DOCUMENT_CATEGORIES_CREATE: async (_, payload) =>
      await DocumentService.createCategory(payload),
    DOCUMENT_CATEGORIES_DELETE: async (_, payload) =>
      await DocumentService.removeCategory(payload),
  },
  getters: {},
};
