<template>
  <div>
    <Header v-if="route.name !== 'Landing'" />
    <Main />
    <Footer v-if="route.name !== 'Landing'" />
    <FullLoading v-if="store.state.loading && route.name !== 'Landing'" />
  </div>
</template>

<script>
import Header from "./components/shared/Header.vue";
import Main from "./components/shared/Main.vue";
import Footer from "./components/shared/Footer.vue";
import FullLoading from "./widgets/loading/FullLoading.vue";
import store from "./store";
import { menu } from "./constants";
import { onBeforeMount, provide } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useNotification } from "./hooks";
import { useToast } from "primevue/usetoast";

export default {
  components: {
    Header,
    Main,
    Footer,
    FullLoading,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const toast = useToast();
    store.commit("setToast", toast);

    onBeforeMount(async () => {
      store.commit("setLoading", true);
      const { getDevice } = useNotification();
      await store.dispatch("GENERAL_DATA");
      if (sessionStorage.getItem("token")) {
        const device = await getDevice;
        const response = await store.dispatch("AUTH_USER", { router, device });
        if (!response) {
          sessionStorage.clear();
          location.href = "/";
        }
      } else {
        store.commit("setRole", { name: "", description: "" }),
          store.commit("setMenu", menu.loginMenu);
      }
      store.commit("setLoading", false);
    });

    return { store, route };
  },
};
</script>

<style>
:root {
  --primary-light: #d6f0f0;
  --primary: #1da4a6;
  --primary-dark: #215973;
  --danger: #d32f2f;
  --xs: 375px;
  --sm: 500px;
  --md: 768px;
  --lg: 992px;
  --xl: 1200px;
  --xxl: 1440px;
  --xxxl: 1880px;
}
body {
  margin: 0 auto;
  max-width: 1920px;
  min-height: 100vh;
  position: relative;
}
* {
  margin: 0;
  box-sizing: border-box;
}
@font-face {
  font-family: "klavika";
  src: url("./assets/fonts/Klavika/klavika-light.otf");
}
h1,
h2,
h3,
h4,
h5,
a,
p,
small {
  font-family: "klavika";
}
.left {
  text-align: left !important;
}
.right {
  text-align: right !important;
}
.center {
  text-align: center !important;
}
.p-dropdown .p-dropdown-label {
  font-size: 1rem !important;
}
.p-dropdown .p-dropdown-label.p-placeholder {
  color: var(--primary) !important;
  font-family: "klavika";
  font-size: 1rem;
  display: flex;
  align-items: center;
}
.pi-chevron-down:before {
  content: "\e902";
  color: var(--primary) !important;
}
.p-dropdown-panel {
  box-shadow: 0 2px 10px rgba(35, 141, 130, 0.5) !important;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  font-family: "klavika";
  font-size: 1rem;
  color: var(--primary) !important;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:hover {
  background-color: var(--primary-light) !important;
}
.p-menu .p-menu-separator {
  border-top: 1px solid var(--primary-light) !important;
}
.p-menu .p-submenu-header {
  color: var(--primary-dark) !important;
  font-family: "klavika" !important;
}
.p-menu.p-menu-overlay {
  box-shadow: 0 2px 10px rgba(35, 141, 130, 0.5) !important;
}
.p-menu .p-menuitem-link:hover {
  background-color: var(--primary-light) !important;
}
.p-menu .p-menuitem-link .p-menuitem-text {
  color: var(--primary) !important;
}
.p-progress-spinner-svg > circle {
  stroke: var(--primary-dark) !important;
}
.p-dropdown .p-dropdown-label {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-family: "klavika";
}
.p-disabled,
.p-component:disabled {
  opacity: 1 !important;
}
.p-sidebar {
  background-color: transparent !important;
  padding: 0 !important;
}
.p-datatable .p-datatable-header {
  background-color: transparent !important;
  border: none !important;
  font-family: "klavika";
  color: var(--primary) !important;
  margin: 1rem;
}
.p-datatable .p-datatable-thead > tr > th {
  border: none !important;
  background-color: var(--primary-light) !important;
  border: 2px solid var(--primary-light) !important;
  color: var(--primary-dark) !important;
  font-family: "klavika";
}
.p-sortable-column .p-column-header-content {
  justify-content: center !important;
}
.p-column-title {
  text-align: center;
}
.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: var(--primary-dark) !important;
}
.p-datatable .p-sortable-column:focus {
  box-shadow: none !important;
}
.p-datatable .p-datatable-tbody {
  border: 2px solid var(--primary-light) !important;
}
.p-datatable .p-datatable-tbody > tr > td {
  /* border: none !important; */
  font-family: "klavika";
  color: gray;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
  background-color: #f8f8f8 !important;
}
.p-checkbox .p-checkbox-box {
  border: 2px solid var(--primary) !important;
  box-shadow: none !important;
}
.p-checkbox .p-checkbox-box:hover {
  border: 2px solid var(--primary) !important;
  box-shadow: none !important;
}
.p-checkbox .p-checkbox-box:focus {
  border: 2px solid var(--primary) !important;
  box-shadow: none !important;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: var(--primary) !important;
  box-shadow: none !important;
}
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: var(--primary) !important;
  background-color: var(--primary) !important;
  box-shadow: none !important;
}
.p-datatable .p-datatable-tbody > tr.p-highlight {
  background-color: transparent !important;
}
.p-datatable .p-paginator-bottom {
  color: var(--primary) !important;
}
.p-paginator .p-paginator-pages .p-paginator-page {
  box-shadow: none !important;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background-color: transparent !important;
  color: var(--primary) !important;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight:focus {
  box-shadow: none !important;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight:active {
  box-shadow: none !important;
}
.p-paginator .p-paginator-pages .p-paginator-page:hover {
  background-color: var(--primary-light) !important;
}
.p-paginator .p-paginator-first:hover,
.p-paginator .p-paginator-prev:hover,
.p-paginator .p-paginator-next:hover,
.p-paginator .p-paginator-last:hover {
  background-color: var(--primary-light) !important;
}
.p-datatable .p-paginator-bottom {
  border: none !important;
}
.p-datatable .p-row-toggler:focus {
  box-shadow: none !important;
}
.p-datatable .p-row-toggler:hover {
  background-color: var(--primary-light) !important;
}
.p-tree {
  border: 2px solid var(--primary) !important;
  border-radius: 0.5rem !important;
  box-shadow: 0 10px 50px rgba(35, 141, 130, 0.15) !important;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content {
  color: var(--primary) !important;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  background-color: var(--primary-light) !important;
  color: var(--primary-dark) !important;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
  box-shadow: none !important;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content:hover {
  background-color: var(--primary-light) !important;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
  color: var(--primary) !important;
}
.p-tree
  .p-tree-container
  .p-treenode
  .p-treenode-content
  .p-tree-toggler:hover {
  background-color: var(--primary-light) !important;
}
.p-tree
  .p-tree-container
  .p-treenode
  .p-treenode-content
  .p-tree-toggler:focus {
  box-shadow: none !important;
}
.p-calendar .p-inputtext {
  border: none !important;
  font-family: "klavika";
  color: var(--primary) !important;
  box-shadow: none !important;
}
.p-calendar .p-inputtext::placeholder {
  color: var(--primary) !important;
}
.p-calendar-w-btn .p-datepicker-trigger {
  background-color: var(--primary) !important;
  border: none !important;
  border-radius: 0 !important;
  border-top-right-radius: 0.2rem !important;
  border-top-left-radius: 0.2rem !important;
  box-shadow: none !important;
}
.p-datepicker {
  font-family: "klavika" !important;
}
.p-datepicker table td.p-datepicker-today > span {
  background-color: var(--primary) !important;
  color: #ffffff !important;
}
.p-datepicker table td > span {
  color: var(--primary-dark) !important;
}
.p-datepicker table td > span:hover {
  background-color: var(--primary-light) !important;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:hover,
.p-datepicker .p-datepicker-header .p-datepicker-next:hover {
  background-color: var(--primary-light) !important;
}
.p-datepicker table td > span.p-highlight {
  background-color: var(--primary-light) !important;
}
.p-datepicker table td > span.p-disabled {
  color: var(--primary-light) !important;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  background-color: var(--primary-light) !important;
  box-shadow: none !important;
}
.fc .fc-toolbar .fc-button-group .fc-button:first-child,
.fc .fc-toolbar .fc-button-group .fc-button:last-child {
  background-color: var(--primary-light) !important;
  border-color: var(--primary-light) !important;
  box-shadow: none !important;
  color: var(--primary);
  font-family: "klavika";
}
.fc .fc-toolbar .fc-button-group .fc-button:first-child:hover,
.fc .fc-toolbar .fc-button-group .fc-button:last-child:hover {
  color: var(--primary-dark) !important;
}
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active,
.fc .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active,
.fc .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
  color: var(--primary) !important;
  background-color: var(--primary-light) !important;
  border-color: var(--primary-light) !important;
  font-family: "klavika" !important;
  box-shadow: none !important;
}
.fc .fc-daygrid-day-top {
  color: var(--primary-dark) !important;
}
.fc .fc-view-harness th {
  background-color: var(--primary-light) !important;
  color: var(--primary-dark) !important;
}
.fc .fc-daygrid-day.fc-day-today {
  background-color: transparent !important;
}
.fc .fc-view-harness .fc-event,
.fc .fc-view-harness .fc-event-main {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  cursor: pointer !important;
}
.fc .fc-toolbar-title {
  font-size: 1.5rem !important;
  color: var(--primary-dark) !important;
  text-transform: uppercase !important;
}
.fc .fc-timegrid-axis-frame,
.fc-direction-ltr .fc-timegrid-slot-label-frame {
  font-family: "klavika" !important;
  color: var(--primary-dark) !important;
}
.p-datepicker .p-timepicker button:enabled:hover {
  background-color: var(--primary-light) !important;
}
.p-datepicker .p-timepicker > div {
  color: var(--primary-dark) !important;
}
.p-toast .p-toast-message.p-toast-message-warn,
.p-toast .p-toast-message.p-toast-message-error,
.p-toast .p-toast-message.p-toast-message-info,
.p-toast .p-toast-message.p-toast-message-success {
  background-color: var(--primary-light) !important;
  border-color: var(--primary) !important;
  color: var(--primary-dark) !important;
}
.p-fileupload .p-progressbar {
  display: none !important;
}
.p-fileupload .p-fileupload-buttonbar {
  background-color: transparent !important;
  border: none !important;
  border-bottom: 2px solid var(--primary) !important;
  padding: 0.5rem !important;
  display: flex !important;
  justify-content: flex-end;
  gap: 0.5rem !important;
}
.p-fileupload .p-fileupload-buttonbar .p-button {
  margin: 0 !important;
  background-color: var(--primary-light) !important;
  border-color: var(--primary-light) !important;
  color: var(--primary) !important;
  border-radius: 0.5rem !important;
  font-family: "klavika" !important;
  box-shadow: none !important;
}
.p-fileupload .p-fileupload-buttonbar .p-button:hover {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  color: #ffffff !important;
}
.p-fileupload .p-fileupload-content {
  background-color: transparent !important;
  border: none !important;
  padding: 0.5rem !important;
}
.p-fileupload-row {
  display: flex !important;
  flex-direction: column !important;
  border-top: 1px solid var(--primary-light) !important;
}
.p-fileupload-row > div {
  display: flex;
  font-size: 0.8rem !important;
  width: 350px !important;
  padding: 0.5rem !important;
  font-family: "klavika" !important;
  color: var(--primary-dark) !important;
}
.p-fileupload-row > div > button {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  color: #ffffff !important;
  box-shadow: none !important;
  width: 2rem !important;
  height: 2rem !important;
  margin: -2.6rem 0 0 300px;
}
.p-fileupload-row > div > button:hover {
  background-color: var(--primary-light) !important;
  border-color: var(--primary-light) !important;
  color: var(--primary) !important;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  border-color: var(--primary) !important;
  color: var(--primary) !important;
  box-shadow: none !important;
}
.p-tabview .p-tabview-nav .p-tabview-nav-link span {
  margin-left: 1rem;
}
.p-tabview .p-tabview-nav .p-tabview-nav-link {
  color: var(--primary-dark) !important;
}
.p-tabview .p-tabview-nav .p-tabview-nav-link:hover {
  color: var(--primary-dark) !important;
  border-color: var(--primary-dark) !important;
}
.p-tabview {
  align-items: center;
}
.p-tabview .p-tabview-nav {
  display: flex;
  margin: 0 5rem;
}
@media screen and (max-width: 767px) {
  .p-tabview .p-tabview-nav {
    justify-content: center;
    margin: 0 3rem;
  }
}
.p-multiselect-panel .p-multiselect-header {
  color: var(--primary-dark) !important;
  background-color: var(--primary-light) !important;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
  color: var(--primary-dark) !important;
  font-family: "klavika" !important;
  text-align: center !important;
}
.p-multiselect-panel
  .p-multiselect-header
  .p-multiselect-filter-container
  .p-inputtext {
  font-family: "klavika" !important;
}
.p-multiselect-panel
  .p-multiselect-header
  .p-multiselect-filter-container
  .p-inputtext:hover {
  border-color: var(--primary-dark) !important;
  box-shadow: none !important;
}
.p-multiselect-panel
  .p-multiselect-header
  .p-multiselect-filter-container
  .p-inputtext:focus {
  border-color: var(--primary-dark) !important;
  box-shadow: none !important;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
  color: var(--primary-dark) !important;
  box-shadow: none !important;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:hover {
  background-color: var(--primary-light) !important;
}
.p-multiselect-panel
  .p-multiselect-header
  .p-multiselect-filter-container
  .p-multiselect-filter-icon {
  color: var(--primary-dark) !important;
}
.p-multiselect-panel
  .p-multiselect-items
  .p-multiselect-item:not(.p-highlight):not(.p-disabled) {
  box-shadow: none !important;
}
.p-multiselect-panel
  .p-multiselect-items
  .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  background-color: var(--primary-light) !important;
}
.p-multiselect .p-multiselect-label.p-placeholder {
  color: var(--primary) !important;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
  color: var(--primary-dark) !important;
  font-family: "klavika" !important;
  text-align: center !important;
}
.p-rowgroup-header {
  border: 1px solid var(--primary-light);
}
.p-datepicker:not(.p-datepicker-inline) {
  margin-top: 2rem;
}
</style>
