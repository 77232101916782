import { tuApptoAPI } from "./config";

export const findAll = async (id) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.get(`/commonexpenses/findall/${id}`, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
export const findAllDetail = async (id) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.get(`/commonexpenses/detail/${id}`, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
export const findAllClosures = async (id) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.get(`/commonexpenses/closures/${id}`, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
export const findAllMovements = async (id) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.get(`/commonexpenses/movements/${id}`, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
export const create = async (request) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.post("/commonexpenses/create", request, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
export const createDetail = async (request) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.post(
      "/commonexpenses/create/detail",
      request,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
export const createMovement = async (request) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.post(
      "/commonexpenses/create/movement",
      request,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
export const generate = async (request) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.post(
      "/commonexpenses/documents/generate",
      request,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
export const send = async (request) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.post(
      "/commonexpenses/documents/send",
      request,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
export const confirm = async (request) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.post(
      "/commonexpenses/payments/confirm",
      request,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
export const saveDocument = async (request) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.post(
      "/commonexpenses/documents/save",
      request,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
export const detailTemplate = async () => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.get(
      "/docs/templates/commonexpenses/detail",
      {
        responseType: "blob",
        headers,
      }
    );
    const blob = new Blob([response.data], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
    });
    const aEle = document.createElement("a");
    const href = window.URL.createObjectURL(blob);
    aEle.href = href;
    aEle.download = "Plantilla_detalle_gastos_comunes.xlsx";
    document.body.appendChild(aEle);
    aEle.click();
    document.body.removeChild(aEle);
    window.URL.revokeObjectURL(href);
  } catch (error) {
    console.log(error);
  }
};
export const movementTemplate = async () => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.get(
      "/docs/templates/commonexpenses/movements",
      {
        responseType: "blob",
        headers,
      }
    );
    const blob = new Blob([response.data], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
    });
    const aEle = document.createElement("a");
    const href = window.URL.createObjectURL(blob);
    aEle.href = href;
    aEle.download = "Plantilla_movimientos_gastos_comunes.xlsx";
    document.body.appendChild(aEle);
    aEle.click();
    document.body.removeChild(aEle);
    window.URL.revokeObjectURL(href);
  } catch (error) {
    console.log(error);
  }
};
export const commonExpensesXls = async (payload) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.post(
      "/docs/commonexpenses/xls",
      payload,
      {
        responseType: "blob",
        headers,
      }
    );
    const blob = new Blob([response.data], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
    });
    const aEle = document.createElement("a");
    const href = window.URL.createObjectURL(blob);
    aEle.href = href;
    aEle.download = `${payload.fileName}.xlsx`;
    document.body.appendChild(aEle);
    aEle.click();
    document.body.removeChild(aEle);
    window.URL.revokeObjectURL(href);
  } catch (error) {
    console.log(error);
  }
};
