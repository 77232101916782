import { tuApptoAPI } from "./config";

export const findAll = async (request) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.post("/documents/findall", request, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getPath = async (request) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.post("/documents/getpath", request, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const upload = async (request) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.post("/documents/upload", request, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const categories = async (request) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.post(
      "/documents/categories/findall",
      request,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const createCategory = async (request) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.post(
      "/documents/categories/create",
      request,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const removeCategory = async (id) => {
  const headers = {
    "x-token": sessionStorage.getItem("token"),
  };
  try {
    const response = await tuApptoAPI.delete(
      `/documents/categories/delete/${id}`,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
