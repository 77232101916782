import { ResidentService } from "../services";

export const resident = {
  state: () => ({
    residents: [],
    selectedResidents: [],
    residentsApartments: [],
    selectedResidentsApartments: [],
  }),
  mutations: {
    setResidents: (state, value) => (state.residents = value),
    setSelectedResidents: (state, value) => (state.selectedResidents = value),
    setResidentsApartments: (state, value) =>
      (state.residentsApartments = value),
    setSelectedResidentsApartments: (state, value) =>
      (state.selectedResidentsApartments = value),
  },
  actions: {
    RESIDENT_FIND_ALL: async ({ commit }, payload) => {
      const response = await ResidentService.findAll(payload);
      if (response.ok) {
        commit("setResidents", response.residents);
      } else {
        alert(response.message);
      }
    },
    RESIDENT_CREATE: async (_, payload) =>
      await ResidentService.create(payload),
    RESIDENT_FIND_ALL_APARTMENTS: async ({ commit }, payload) => {
      const response = await ResidentService.apartments(payload);
      if (response.ok) {
        commit("setResidentsApartments", response.apartments);
      } else {
        alert(response.message);
      }
    },
    RESIDENT_ASSOCIATE: async (_, payload) =>
      await ResidentService.associate(payload),
    RESIDENT_DEASSOCIATE: async (_, payload) =>
      await ResidentService.deassociate(payload),
    RESIDENT_CHANGE_STATUS: async (_, payload) =>
      await ResidentService.status(payload),
  },
  getters: {},
};
