import { UserService } from "../services";

export const user = {
  state: () => ({
    users: [],
    selectedUsers: [],
  }),
  mutations: {
    setUsers: (state, value) => (state.users = value),
    setSelectedUsers: (state, value) => (state.selectedUsers = value),
  },
  actions: {
    USER_FIND_ALL: async ({ commit }) => {
      const response = await UserService.findAll();
      if (response.ok) {
        commit("setUsers", response.users);
      } else {
        alert(response.message);
      }
    },
    USER_CREATE: async (_, payload) => await UserService.create(payload),
    USER_UPDATE: async (_, payload) => await UserService.update(payload),
    USER_CHANGE_STATUS: async (_, payload) => await UserService.status(payload),
  },
  getters: {},
};
