import { ParkingService } from "../services";

export const parking = {
  state: () => ({
    parkings: [],
    selectedParkings: [],
  }),
  mutations: {
    setParkings: (state, value) => (state.parkings = value),
    setSelectedParkings: (state, value) => (state.selectedParkings = value),
  },
  actions: {
    PARKING_FIND_ALL: async ({ commit }, payload) => {
      const response = await ParkingService.findAll(payload);
      if (response.ok) {
        commit("setParkings", response.parkings);
      } else {
        alert(response.message);
      }
    },
    PARKING_CREATE: async (_, payload) => await ParkingService.create(payload),
    PARKING_UPDATE: async (_, payload) => await ParkingService.update(payload),
    PARKING_DELETE: async (_, payload) => await ParkingService.remove(payload),
  },
  getters: {},
};
