const AWS = require("aws-sdk");

const s3 = new AWS.S3({
  accessKeyId: process.env.VUE_APP_AWS_SECRET_ID,
  secretAccessKey: process.env.VUE_APP_AWS_SECRET_KEY,
});

export const downloadFile = (url, key, callback) => {
  const params = {
    Bucket: process.env.VUE_APP_AWS_BUCKET_NAME,
    Key: key,
  };
  s3.getObject(params, (err, data) => {
    if (err) throw err;

    let blob = new Blob([data.Body], { type: data.ContentType });
    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = url;
    link.click();
    link.remove();
    callback(data);
  });
};

export const getFile = (key, callback) => {
  const params = {
    Bucket: process.env.VUE_APP_AWS_BUCKET_NAME,
    Key: key,
  };
  s3.getObject(params, (err, data) => {
    if (err) throw err;

    callback(data.Body.toString("base64"));
  });
};

export const uploadFile = (key, body, callback) => {
  const params = {
    Bucket: process.env.VUE_APP_AWS_BUCKET_NAME,
    Key: key,
    Body: body,
  };

  s3.upload(params, (err, data) => {
    if (err) throw err;

    callback(data);
  });
};
