/** Master */
export const masterRoutes = [
  { label: "Administradoras", to: "/administradoras" },
  { label: "Complejos", to: "/complejos" },
  { label: "Usuarios", to: "/usuarios" },
];

/** Admin */
export const adminRoutes = [
  { key: "", label: "Contabilidad", to: "" },
  { key: "commonExpenses", label: "Gastos Comunes", to: "/gastos-comunes" },
  { key: "building", label: "Complejo", to: "/complejo" },
  { key: "reception", label: "Portería", to: "/porteria" },
  { key: "assembly", label: "Asambleas", to: "/asambleas" },
  { key: "message", label: "Mensajería", to: "/mensajes" },
  { key: "personnel", label: "Personal", to: "/personal" },
];

export const configurationMenu = [
  { label: "Perfiles", to: "/perfiles/listado" },
  { label: "Empleados", to: "/empleados/listado" },
  { label: "Complejos", to: "/complejos/listado" },
];

/** Login */
export const loginMenu = [
  // { label: "Solicitar Acceso", to: "/access" },
  // { label: "Nosotros", to: "" },
  // { label: "Contacto", url: "#contact" },
  { label: "Términos y Condiciones", url: "/terms" },
  { label: "Política de Privacidad", url: "/policy" },
];
