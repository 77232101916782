<template>
  <img
    width="80"
    src="../../assets/images/logoCentradoLinealBLANCO.png"
    alt="Logo TuAppto"
    class="logo-vertical"
  />
  <img
    width="250"
    src="../../assets/images/logoPrincipalLinealBLANCO.png"
    alt="Logo TuAppto"
    class="logo-horizontal"
  />
</template>

<script>
export default {};
</script>

<style scoped>
.logo-vertical {
  margin-left: 2rem;
}
.logo-horizontal {
  margin-left: 2rem;
  display: none;
}
@media screen and (max-width: 500px) {
  .logo-vertical {
    margin-left: 1rem;
  }
}
@media screen and (min-width: 801px) {
  .logo-vertical {
    display: none;
  }
  .logo-horizontal {
    display: block;
  }
}
</style>
