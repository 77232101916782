import { ApartmentService } from "../services";

export const apartment = {
  state: () => ({
    apartments: [],
    selectedApartments: [],
  }),
  mutations: {
    setApartments: (state, value) => (state.apartments = value),
    setSelectedApartments: (state, value) => (state.selectedApartments = value),
  },
  actions: {
    APARTMENT_FIND_ALL: async ({ commit }, payload) => {
      const response = await ApartmentService.findAll(payload);
      if (response.ok) {
        commit("setApartments", response.apartments);
      } else {
        alert(response.message);
      }
    },
    APARTMENT_CREATE: async (_, payload) =>
      await ApartmentService.create(payload),
    APARTMENT_UPDATE: async (_, payload) =>
      await ApartmentService.update(payload),
    APARTMENT_DELETE: async (_, payload) =>
      await ApartmentService.remove(payload),
  },
  getters: {},
};
