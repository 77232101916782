import { EmployeeService } from "../services";

export const employee = {
  state: () => ({
    employees: [],
    selectedEmployees: [],
  }),
  mutations: {
    setEmployees: (state, value) => (state.employees = value),
    setSelectedEmployees: (state, value) => (state.selectedEmployees = value),
  },
  actions: {
    EMPLOYEE_FIND_ALL: async ({ commit }, payload) => {
      const response = await EmployeeService.findAll(payload);
      if (response.ok) {
        commit("setEmployees", response.employees);
      } else {
        alert(response.message);
      }
    },
    EMPLOYEE_CREATE: async (_, payload) =>
      await EmployeeService.create(payload),
    EMPLOYEE_UPDATE: async (_, payload) =>
      await EmployeeService.update(payload),
    EMPLOYEE_CHANGE_STATUS: async (_, payload) =>
      await EmployeeService.status(payload),
  },
  getters: {},
};
