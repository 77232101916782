import { GeneralService } from "../services";

export const general = {
  state: () => ({
    companies: [],
    roles: [],
    countries: [],
    states: [],
    cities: [],
    plans: [],
    unitType: [],
    identityType: [],
    selectedBuilding: null,
    breadcrumbs: [],
    apartments: [],
    commonAreas: [],
    frequency: [],
    personnelType: [],
  }),
  mutations: {
    setGeneralCountries: (state, value) => (state.countries = value),
    setGeneralCompanies: (state, value) => (state.companies = value),
    setGeneralRoles: (state, value) => (state.roles = value),
    setGeneralStates: (state, value) => (state.states = value),
    setGeneralCities: (state, value) => (state.cities = value),
    setGeneralPlans: (state, value) => (state.plans = value),
    setGeneralUnitType: (state, value) => (state.unitType = value),
    setGeneralIdentityType: (state, value) => (state.identityType = value),
    setGeneralSelectedBuilding: (state, value) =>
      (state.selectedBuilding = value),
    addBreadcrumb: (state, value) => state.breadcrumbs.push(value),
    removeBreadcrumb: (state) => state.breadcrumbs.pop(),
    setBreadcrumbs: (state, value) => (state.breadcrumbs = value),
    setApartments: (state, value) => (state.apartments = value),
    setGeneralCommonAreas: (state, value) => (state.commonAreas = value),
    setGeneralFrequency: (state, value) => (state.frequency = value),
    setPersonnelType: (state, value) => (state.personnelType = value),
  },
  actions: {
    GENERAL_DATA: async ({ commit }) => {
      const response = await GeneralService.general();
      if (response.ok) {
        commit("setGeneralCountries", response.countries);
        commit("setGeneralStates", response.states);
        commit("setGeneralCities", response.cities);
        commit("setGeneralPlans", response.plans);
        commit("setGeneralUnitType", response.unitType);
        commit("setGeneralIdentityType", response.identityType);
      } else {
        alert(response.message);
      }
    },
    GENERAL_COMPANIES: async ({ commit }, payload) => {
      const response = await GeneralService.companies(payload);
      if (response.ok) {
        commit("setGeneralCompanies", response.companies);
      } else {
        alert(response.message);
      }
    },
    GENERAL_ROLES: async ({ commit }, payload) => {
      const response = await GeneralService.roles(payload);
      if (response.ok) {
        commit("setGeneralRoles", response.roles);
      } else {
        alert(response.message);
      }
    },
    GENERAL_APARTMENTS: async ({ commit }, payload) => {
      const response = await GeneralService.apartments(payload);
      if (response.ok) {
        commit("setApartments", response.apartments);
      } else {
        alert(response.message);
      }
    },
    GENERAL_COMMON_AREAS: async ({ commit }, payload) => {
      const response = await GeneralService.commonareas(payload);
      if (response.ok) {
        commit("setGeneralCommonAreas", response.commonAreas);
      } else {
        alert(response.message);
      }
    },
    GENERAL_FREQUENCY: async ({ commit }) => {
      const response = await GeneralService.frequency();
      if (response.ok) {
        commit("setGeneralFrequency", response.frequency);
      } else {
        alert(response.message);
      }
    },
    GENERAL_GET_SIGNED_URL: async (_, payload) =>
      await GeneralService.getSignedUrl(payload),
    GENERAL_PERSONNEL_TYPE: async ({ commit }) => {
      const response = await GeneralService.personnelType();
      if (response.ok) {
        commit("setPersonnelType", response.personnelType);
      } else {
        alert(response.message);
      }
    },
  },
  getters: {},
};
