export const emailRegex = /^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,63}$/i;
export const phoneRegex = /^\d{8}$/;
export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&#.$($)$-$_])[A-Za-z\d$@$!%*?&#.$($)$-$_]{8,15}$/;
export const numberRegex = /^\d+$/;
export const extensionRegex = /(?:\.([^.]+))?$/;
export const characterRegex = /^[a-zA-Z\d\s$.$($)$_$-]+$/;

const cleanCI = (ci) => ci.replace(/\D/g, "");
export const validateDigitCI = (ci) => {
  let a = 0;
  let i = 0;
  if (ci.length <= 6) {
    for (i = ci.length; i < 7; i++) {
      ci = "0" + ci;
    }
  }
  for (i = 0; i < 7; i++) {
    a += (parseInt("2987634"[i]) * parseInt(ci[i])) % 10;
  }
  if (a % 10 === 0) {
    return 0;
  } else {
    return 10 - (a % 10);
  }
};
export const validateCI = (ci) => {
  ci = cleanCI(ci);
  if (ci[0] == 0) return false;
  const dig = ci[ci.length - 1];
  ci = ci.replace(/[0-9]$/, "");
  return dig == validateDigitCI(ci);
};
export const randomCI = () => {
  let ci = Math.floor(Math.random() * 10000000).toString();
  ci = ci.substring(0, 7) + validateDigitCI(ci);
  return ci;
};
export const validateRUT = (rut) => {
  if (rut.length !== 12) {
    return false;
  }
  if (!/^([0-9])*$/.test(rut)) {
    return false;
  }
  const dc = rut.substr(11, 1);
  rut = rut.substr(0, 11);
  let total = 0;
  let factor = 2;

  for (let i = 10; i >= 0; i--) {
    total += factor * rut.substr(i, 1);
    factor = factor == 9 ? 2 : ++factor;
  }

  let dv = 11 - (total % 11);

  if (dv === 11) {
    dv = 0;
  } else if (dv === 10) {
    dv = 1;
  }
  if (dv == dc) {
    return true;
  }
  return false;
};

export const getRndInteger = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
