<template>
  <footer class="footer">
    <WhiteVerticalLogo />
    <div class="footer-content">
      <!-- <ul>
        <li><a href="">Solicitar Acesso</a></li>
        <li><a href="">Nosotros</a></li>
      </ul> -->
      <div class="footer-data">
        <p>Montevideo UY</p>
        <p>+598 000 000 00</p>
        <p><strong>info@tuappto.com</strong></p>
      </div>
    </div>
  </footer>
</template>

<script>
import WhiteVerticalLogo from "../../widgets/logo/WhiteLogo.vue";
export default {
  components: { WhiteVerticalLogo },
};
</script>

<style scoped>
.footer {
  background-color: var(--primary);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer-content {
  padding: 2rem;
  display: flex;
}
.footer-content > ul {
  padding-left: 0;
}
.footer-content > ul > li {
  list-style: none;
  text-align: right;
}
.footer-content > ul > li > a {
  text-decoration: none;
  color: white;
  font-size: 0.9rem;
}
.footer-data {
  margin-left: 2rem;
  padding-left: 2rem;
  border-left: 2px solid var(--primary-dark);
  color: white;
  line-height: 1.4rem;
  font-size: 0.9rem;
}
</style>
