import { RoleService } from "../services";

export const role = {
  state: () => ({
    roles: [],
    selectedRoles: [],
  }),
  mutations: {
    setRoles: (state, value) => (state.roles = value),
    setSelectedRoles: (state, value) => (state.selectedRoles = value),
  },
  actions: {
    ROLE_FIND_ALL: async ({ commit }, payload) => {
      const response = await RoleService.findAll(payload);
      if (response.ok) {
        commit("setRoles", response.roles);
      } else {
        alert(response.message);
      }
    },
    ROLE_CREATE: async (_, payload) => await RoleService.create(payload),
    ROLE_UPDATE: async (_, payload) => await RoleService.update(payload),
  },
  getters: {},
};
